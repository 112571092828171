import { DeleteRuleButton } from "components/Rosetta/Buttons";
import React from "react";
import DropDownList from "../DropDownList";

export default class Rule extends React.Component<any, any> {
  static get defaultProps() {
    return {
      id: null,
      parentId: null,
      field: null,
      operator: null,
      value: null,
      schema: null,
    };
  }

  public render() {
    const {
      field,
      operator,
      value,
      translations,
      schema: { fields, controls, getOperators, getLevel, classNames },
    } = this.props;
    const level = getLevel(this.props.id);
    return (
      <div
        className={`rule-container  p-0 row justify-content-end align-items-center ${classNames.rule}`}
        style={{ background: "#f5f5f5" }}
      >
        <div className="col-12 col-md-4" style={{ padding: "5px" }}>
          {React.createElement(controls.fieldSelector, {
            options: fields,
            title: translations.fields.title,
            value: field,
            className: `rule-fields  ${classNames.fields}`,
            handleOnChange: this.onFieldChanged,
            level,
            larger: true,
          })}
        </div>
        <div className="col-12 col-md-2" style={{ padding: "5px" }}>
          <DropDownList
            onChange={this.onOperatorChanged}
            tags={getOperators(field)}
            selected={operator}
            className="mw-100"
          />
        </div>
        <div className="col-12 col-md-4" style={{ padding: "5px" }}>
          {React.createElement(controls.valueEditor, {
            field,
            title: translations.value.title,
            operator,
            value,
            className: `rule-value form-group mb-0`,
            handleOnChange: this.onValueChanged,
            level,
            fields,
          })}
        </div>
        <div className="col-12 col-md-2">
          <div style={{ width: "80px", marginLeft: "auto" }}>
            <DeleteRuleButton onClick={this.removeRule} />
          </div>
        </div>
      </div>
    );
  }

  public onFieldChanged = (value: any) => {
    this.onElementChanged("field", value);
  };

  public onOperatorChanged = (value: any) => {
    this.onElementChanged("operator", value);
  };

  public onValueChanged = (value: any) => {
    this.onElementChanged("value", value);
  };

  public onElementChanged = (property: string, value: any) => {
    const {
      id,
      schema: { onPropChange },
    } = this.props;

    onPropChange(property, value, id);
  };

  public removeRule = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.schema.onRuleRemove(this.props.id, this.props.parentId);
  };
}
