import React from "react";
import Rule from "./Rule";
import DropDownList from "../DropDownList";
import { AddRuleButton, AddRulesGroupButton, DeleteRulesGroupButton } from "components/Rosetta/Buttons";

export default class RuleGroup extends React.Component<any> {
  static get defaultProps() {
    return {
      id: null,
      parentId: null,
      rules: [],
      combinator: "and",
      schema: {},
    };
  }

  public render() {
    const {
      combinator,
      rules,
      schema: { combinators, onRuleRemove, isRuleGroup, classNames },
    } = this.props;
    return (
      <div
        className={`${classNames.ruleGroup} rules-group-container`}
        style={{
          background: "#fff",
          border: "1px solid #dddedf",
          width: "100%",
        }}
      >
        <div className="rules-group-header">
          <div className="btn-group pull-right group-actions">
            <AddRuleButton onClick={this.addRule} />
            <AddRulesGroupButton onClick={this.addGroup} />
            {this.hasParentGroup() && <DeleteRulesGroupButton onClick={this.removeGroup} />}
          </div>
          <div className="form-group mb-0">
            <DropDownList onChange={this.onCombinatorChange} tags={combinators} selected={combinator} />
          </div>
        </div>

        <div className="rules-group-body">
          <div className="rules-list">
            {rules.map((r: any) => {
              return isRuleGroup(r) ? (
                <RuleGroup
                  key={r.id}
                  id={r.id}
                  schema={this.props.schema}
                  parentId={this.props.id}
                  combinator={r.combinator}
                  translations={this.props.translations}
                  rules={r.rules}
                />
              ) : (
                <Rule
                  key={r.id}
                  id={r.id}
                  field={r.field}
                  value={r.value}
                  operator={r.operator}
                  schema={this.props.schema}
                  parentId={this.props.id}
                  translations={this.props.translations}
                  onRuleRemove={onRuleRemove}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  public hasParentGroup() {
    return this.props.parentId;
  }

  public onCombinatorChange = (value: any) => {
    const { onPropChange } = this.props.schema;

    onPropChange("combinator", value, this.props.id);
  };

  public addRule = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
    event.preventDefault();
    event.stopPropagation();

    const { createRule, onRuleAdd } = this.props.schema;

    const newRule = createRule();
    onRuleAdd(newRule, this.props.id);
  };

  public addGroup = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
    event.preventDefault();
    event.stopPropagation();

    const { createRuleGroup, onGroupAdd } = this.props.schema;
    const newGroup = createRuleGroup();
    onGroupAdd(newGroup, this.props.id);
  };

  public removeGroup = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.schema.onGroupRemove(this.props.id, this.props.parentId);
  };
}
