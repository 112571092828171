import React from "react";

interface Props {
  title: string;
  children?: React.ReactNode;
}

const ErrorCanvas: React.FC<Props> = ({ title, children = null }: Props) => {
  return (
    <div className="row">
      <div className="col my-3 text-center text-secondary">
        <i className="icon icon-xl d-inline-block mb-2">error</i>
        <div className="display-4">{title}</div>
        {children}
      </div>
    </div>
  );
};

export default ErrorCanvas;
