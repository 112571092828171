import { IColumn } from "api/typings";
import React from "react";
import { DragDropContext, Droppable, Draggable, DraggableStateSnapshot, NotDraggingStyle } from "react-beautiful-dnd";
import "./sortable-list.css";
import { InputPrefix } from "components/common/InputPrefix/InputPrefix";
import classNames from "classnames";
import { isContainSubString } from "common/utils/stringUtils";
import FieldTooltip from "components/Rosetta/FieldTooltip";

export interface ISortableListProps {
  items: IColumn[];
  itemsSelected: IColumn[];
  onSelectItem: (itemSelected: string, position: number) => void;
  onUnSelectItem: (itemUnselected: string, position: number) => void;
  onOrderChanged: (items: IColumn[]) => void;
}

export interface ISortableListState {
  items: IColumn[];
  itemsSelected: IColumn[];
  filterText: string;
}

export class SortableList extends React.Component<ISortableListProps, ISortableListState> {
  constructor(props: ISortableListProps) {
    super(props);

    this.state = {
      filterText: "",
      items: this.props.items,
      itemsSelected: this.props.itemsSelected,
    };
  }

  public componentDidUpdate(prevProps: ISortableListProps) {
    if (prevProps.items !== this.props.items || prevProps.itemsSelected !== this.props.itemsSelected) {
      this.setState({
        items: this.props.items,
        itemsSelected: this.props.itemsSelected,
      });
    }
  }

  public onDragEnd = (result: { source: any; destination: any; draggableId: any }) => {
    const { source, destination, draggableId } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = this.reorder(this.getList(source.droppableId), source.index, destination.index);

      if (source.droppableId === "droppableSelectedItems") {
        this.setState({ itemsSelected: items });
        this.props.onOrderChanged(items);
      } else {
        this.setState({ items });
      }
    } else {
      if (destination.droppableId === "droppableSelectedItems") {
        this.props.onSelectItem(draggableId, destination.index);
      } else {
        this.props.onUnSelectItem(this.getList(source.droppableId)[source.index].key, destination.index);
      }
    }
  };

  public getItemsFilteredAndSorted(filter: string, items: IColumn[]): IColumn[] {
    if (filter.trim().indexOf(" ") > 0) {
      const splitFilters: string[] = filter.trim().split(" ");

      for (const splitFilter of splitFilters) {
        items = items.filter((item) => isContainSubString(item.label, splitFilter, true, true));
      }
      items = this.sortFilterResults(items);
      items = this.smartFilter(items, filter);
    } else {
      items = items.filter((item) => isContainSubString(item.label, filter, true, true));
      items = this.sortFilterResults(items);
    }
    return items;
  }

  public render() {
    return (
      <div className={"sortableList"}>
        <div className="form-group mb-0 has-feedback" style={{ width: "260px" }}>
          <div className={classNames("input-group", "lg")}>
            <InputPrefix iconName="search" />
            <input
              type="search"
              className="form-control"
              placeholder={"Quick search"}
              value={this.state.filterText}
              onChange={this.setFilter.bind(this)}
              onInput={this.setFilter.bind(this)}
            />
          </div>
        </div>

        <DragDropContext onDragEnd={this.onDragEnd as any}>
          <Droppable droppableId="droppableItemsList">
            {(provided, snapshot) => (
              <ul
                ref={provided.innerRef}
                style={this.getListStyle(snapshot.isDraggingOver)}
                className="list-group list-group-pills list-group-drag ui-sortable"
              >
                {this.getItemsFilteredAndSorted(this.state.filterText, this.state.items).map(
                  ({ key, description, example, label }, index) => (
                    <Draggable key={key} draggableId={key} index={index}>
                      {(provided, snapshot) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={this.getItemStyle(snapshot, provided.draggableProps.style)}
                          data-tip={true}
                          data-for={key}
                          className="list-group-item pt-1 pb-0"
                        >
                          <FieldTooltip {...{ itemKey: key, description, example }} />
                          <i className="fa fa-arrows-v pull-right" />
                          {label}
                        </li>
                      )}
                    </Draggable>
                  )
                )}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
          <Droppable droppableId="droppableSelectedItems">
            {(provided, snapshot) => (
              <ul
                ref={provided.innerRef}
                style={this.getListStyle(snapshot.isDraggingOver)}
                className="list-group list-group-pills list-group-drag ui-sortable"
              >
                {this.state.itemsSelected.map(({ key, description, example, label }, index) => (
                  <Draggable key={key} draggableId={key} index={index}>
                    {(provided, snapshot) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        data-tip={true}
                        data-for={key}
                        className="list-group-item pt-1 pb-0"
                      >
                        <FieldTooltip {...{ itemKey: key, description, example }} />
                        <i className="fa fa-arrows-v pull-right" />
                        {label}
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }

  // a little function to help us with reordering the result
  private reorder(list: IColumn[], startIndex: number, endIndex: number): IColumn[] {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  private getList(id: string | number): any {
    const id2List: any = {
      droppableItemsList: "items",
      droppableSelectedItems: "itemsSelected",
    };
    return (this as any).state[id2List[id]];
  }

  private getItemStyle(snapshot: any, draggableStyle: NotDraggingStyle | undefined) {
    if (!snapshot.dropping) {
      return draggableStyle;
    }
    // styles we need to apply on draggables
    return {
      ...draggableStyle, // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`,
    };
  }

  private getListStyle(isDraggingOver: boolean) {
    return { background: isDraggingOver ? "#e6e6e6" : "" };
  }

  private setFilter(event: any) {
    this.setState({ ...this.state, filterText: event.target.value });
  }

  private sortFilterResults(list: IColumn[]) {
    return list.sort((a: any, b: any): any => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
    });
  }

  private smartFilter(list: IColumn[], filter: string) {
    return list.sort((x, y) =>
      x.label.toLowerCase() === filter.toLowerCase() ? -1 : y.label.toLowerCase() === filter.toLowerCase() ? 1 : 0
    );
  }
}
