import { Reducer } from "react";
import { Action } from "components/Rosetta/reducers/actions";
import { AppState } from "../rosettaPage";

export interface UiState {
  hideResult: boolean;
  showResultText: string;
  hasError: boolean;
}

export const uiReducer: Reducer<AppState, Action> = (state: AppState, action: Action) => {
  if (action.type === "ERROR") {
    return { ...state, hasError: true };
  }
  if (action.type === "UPDATE_RESULT_MESSAGE") {
    const { message, hide } = action.payload;
    return { ...state, hideResult: hide, showResultText: message };
  }
  return state;
};
