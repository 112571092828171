export type Fetch = typeof fetch;

export interface IApiIO {
  baseUri: string;
  userEmail?: string;
  method: string;
  fetch: Fetch;
}

export interface IColumn {
  label: string;
  key: string;
  description: string;
  fieldType: FieldType;
  dataType?: string;
  example: string;
  dimensionId: string;
  dimensionName: string;
  dimensionDescription: string;
}

export enum FieldType {
  KPI,
  Attribute,
  Dimension,
}

export interface HttpResquestHeader {
  authorization: string;
  accessToken?: string;
  userEmail?: string;
  impersonatingAs?: string;
  clientApp?: string;
}

export interface IExploreDimensions {
  dimensions: IDimensions[];
}

export interface IDimensions {
  id: string;
  name: string;
  description?: string;
  attributes: Attribute[];
}

export interface Attribute {
  id: string;
  name: string;
  description: string;
  dataType: string;
  example: string;
}