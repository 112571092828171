import React from "react";
import { map, size, isEmpty, includes, filter, take, noop } from "lodash";
import { isStringEmpty } from "common/utils/stringUtils";
import { SelectDropDown } from "./SelectDropDown";
import { Item } from "./item/item";
import { ItemMessage } from "./item/itemMessage";
import { reverseCharacterSwapping } from "common/component/cpr-query-builder/utils/helper";

export interface Props {
  items: string[];
  selectedItem?: string;
  onChanged?: (selectedItem: string) => void;
  placeHolder?: string;
  capacity?: number;
  closeOnSelect?: boolean;
  iconName?: string;
  disabled?: boolean;
}

export const ValueSelector: React.FunctionComponent<Props> = ({
  items,
  selectedItem = "",
  onChanged = noop,
  placeHolder,
  capacity,
  closeOnSelect,
  iconName,
  disabled = false,
}) => {
  const [inputValue, setInputValue] = React.useState<string>(selectedItem);
  const [isDropDownShown, toggleDropDown] = React.useState<boolean>(false);
  const [selectedItemState, setSelectedItemState] = React.useState<string>(selectedItem);
  const [filterText, setFilterText] = React.useState<string>("");

  React.useEffect(() => {
    setSelectedItemState(selectedItem);
    setInputValue(selectedItem);
  }, [selectedItem, items]);

  React.useEffect(() => {
    if (!isDropDownShown) {
      onChanged(selectedItemState);
    }
  }, [isDropDownShown]);

  React.useEffect(() => {
    setInputValue(selectedItemState);
    if (!!closeOnSelect && !isEmpty(selectedItemState)) {
      toggleDropDown(!isDropDownShown);
    }
    setSelectedItemState(selectedItemState);
  }, [selectedItemState]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelection = (elem: string, event?: any) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (isValidSelection(elem)) {
      setSelectedItemState(reverseCharacterSwapping(elem));
      toggleDropDown(!isDropDownShown);
    }
  };

  const handleQuickSearchChange = (term: string): void => {
    setSelectedItemState(term);
    setFilterText(term);
    if (term && term !== selectedItem) {
      onChanged(term);
    }
  };

  const getFiltredItems = (): string[] => {
    const filtredItems = isStringEmpty(filterText)
      ? items
      : filter(items, (item) => {
          return item.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >= 0;
        });
    return take(filtredItems, 50);
  };

  const handleOnClearClick = () => {
    setSelectedItemState("");
    onChanged("");
  };

  const isValidSelection = (item: string): boolean =>
    !capacity || includes(selectedItemState, item) || size(selectedItemState) < capacity;

  const hasResult = (list: string[]): boolean => list && list.length > 0;

  return (
    <>
      <SelectDropDown
        size={"lg"}
        displayedText={inputValue}
        onToggleDropDown={toggleDropDown}
        isDropDownShown={isDropDownShown}
        withOverflow={true}
        iconName={iconName}
        placeHolder={placeHolder}
        disabled={disabled}
        onInputChange={handleQuickSearchChange}
        onClearClick={() => handleOnClearClick()}
      >
        {hasResult(getFiltredItems()) ? (
          map(getFiltredItems(), (item, index) => {
            return <Item item={item} key={index} onItemSelected={handleSelection} />;
          })
        ) : (
          <ItemMessage message={"No suggestion"} isShown={true} />
        )}
      </SelectDropDown>
    </>
  );
};
