import { trim } from "lodash";

export const isStringEqual = (value?: string, other?: string, trimSpaces = true, insensitive = false): boolean => {
  if (!value && !other) {
    return true;
  }
  const { value1, value2 } = trimSpaces
    ? { value1: trim(value), value2: trim(other) }
    : { value1: value, value2: other };
  return insensitive ? value1!.toLowerCase() === value2!.toLowerCase() : value1 === value2;
};

export const isContainSubString = (value: string, other: string, trimSpaces = true, ignoreCase = true): boolean => {
  const { value1, value2 } = trimSpaces
    ? { value1: trim(value), value2: trim(other) }
    : { value1: value, value2: other };
  return ignoreCase ? value1.toLowerCase().indexOf(value2.toLowerCase()) >= 0 : value1.indexOf(value2) >= 0;
};

export const isStringEmpty = (value?: string): boolean => value === undefined || value === null || trim(value) === "";

export const replaceSpecialCharactersByWhiteSpace = (text: string): string => text.replace(/[^A-Z0-9]+/gi, " ");
