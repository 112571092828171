import React from "react";
import { WithContext as ReactTags } from "react-tag-input";

let didmount = false;
const KeyCodes = {
  // comma: 188,
  enter: 13,
};

export interface IMultiValueOption {
  id: string;
  text: string;
}

export interface IMultiValueState {
  tags: IMultiValueOption[];
  taglist: string;
}

export interface IMultivalueProps {
  handleAddition: (tag: IMultiValueOption) => void;
  handleDelete: (i: number) => void;
  handleInputChange: (value: string) => void;
  value: IMultiValueOption[] | string;
}

const delimiters = [KeyCodes.enter];

export default class MultiValueTextBox extends React.Component<IMultivalueProps, IMultiValueState> {
  constructor(props: IMultivalueProps) {
    super(props);

    let value = this.props.value;
    if (typeof value === "string" && value !== '') {
      value = value.split("+").map((v) => ({
        id: v,
        text: v,
      }));
    }

    this.state = {
      tags: value ? value : [],
      taglist: "",
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
  }

  public handleDelete(i: number) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
    let list = "";
    const tagtoRemove = tags[i].text;
    this.state.tags.forEach((tag) => {
      if (tag.text !== tagtoRemove) {
        const tagwithChecks = tag.text.replace(/,/g, "~,");

        list = list.length === 0 ? tagwithChecks.replace(/~,/g, ",") : `${list}+${tagwithChecks}`;
      }
    });
    this.setState({ taglist: list });
    this.props.handleInputChange(list);
  }

  public handleAddition(tag: IMultiValueOption) {
    const tagProcessed = this.processString(tag);
    if (didmount === true) {
      this.setState({
        tags: tagProcessed[0],
        taglist: tagProcessed[1],
      });
    }
    this.props.handleInputChange(tagProcessed[1]);
  }

  public componentDidMount() {
    didmount = true;
  }

  public componentWillUnmount() {
    didmount = false;
  }
  public render(): JSX.Element {
    return (
      <div>
        <ReactTags
          tags={this.state.tags}
          labelField={"text"}
          placeholder="Please separate values by a line break"
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          delimiters={delimiters}
        />
      </div>
    );
  }
  private processString(tag: IMultiValueOption): [IMultiValueOption[], string] {
    const separators = [";", "\\*", ":", "\\?", "\r"];
    const filteredString = tag.text.split(new RegExp(separators.join("|"))).map((d) => d.trim());
    let tglist = "";
    const tgArray: IMultiValueOption[] = this.state.tags;

    this.state.tags.forEach((element) => {
      const processedString = element.text.replace(/,/g, "~,");
      tglist = tglist.length === 0 ? processedString.replace(/~,/g, ",") : `${tglist}+${processedString}`;
    });
    if (filteredString[0] !== "" && tgArray.find((item) => item.id === filteredString[0]) === undefined) {
      tgArray.push({ id: filteredString[0], text: filteredString[0] });
      const newStr = filteredString[0].replace(/,/g, "~,");
      tglist = tglist !== "" ? `${tglist}+${newStr}` : newStr;
    }

    return [tgArray, tglist];
  }
}
