import React from "react";
import { Params } from "./params";
import { RosettaMessage } from "./reducers/rosettaMessage.reducer";

interface QueryResultProps {
  queryResult: RosettaMessage;
}

type QueryInfoProps = Pick<RosettaMessage, "formulakey" | "api" | "datatype">;

const QueryInfo: React.FC<QueryInfoProps> = ({ formulakey, api, datatype }: QueryInfoProps) => {
  const infos = {
    KEY: formulakey,
    "API Name": api,
    "Data Type": datatype,
  };
  return (
    <>
      {Object.entries(infos).map(([key, value]) => (
        <div key={key} className="d-flex mb-2">
          <div className="text-primary" style={{ minWidth: 150 }}>
            <b>{key}</b>
          </div>
          <span className="text-primary">{value}</span>
        </div>
      ))}
    </>
  );
};

type QueryParametersProps = Pick<RosettaMessage, "parameters"> & { isApiBi: boolean };

const QueryParameters: React.FC<QueryParametersProps> = ({
  parameters: { attributes, kpis, kpifilter, date, attributefilter, filter },
  isApiBi,
}: QueryParametersProps) => {
  const infos = {
    Attribute: attributes,
    kpis: kpis,
    Date: date,
    ...(isApiBi ? { attributefilter } : { filter }),
    kpifilter,
  };
  return (
    <div className="d-flex mb-1">
      <div className="text-primary" style={{ minWidth: 150 }}>
        <b>Params</b>
      </div>
      <span>
        {Object.entries(infos).map(([key, value]) => (
          <div key={key} className="d-flex mb-2">
            <div className="text-secondary" style={{ minWidth: 150 }}>
              <b>{key}</b>
            </div>
            <span className="text-primary">{value}</span>
          </div>
        ))}
      </span>
    </div>
  );
};

const QueryResult: React.FC<QueryResultProps> = ({
  queryResult: { formulakey, api, datatype, parameters },
}: QueryResultProps) => {
  return (
    <>
      <div className="mb-3 mt-4">
        <h4>Rosetta params result</h4>
      </div>
      <QueryInfo {...{ formulakey, api, datatype }} />
      <QueryParameters parameters={parameters} isApiBi={api === Params.ApiName.API_BI} />
    </>
  );
};

export default QueryResult;
