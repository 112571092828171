import { Component } from "react";
import { ComboBox } from "./ComboBox/ComboBox";
import React from "react";

interface ISelectFilterProps {
  items: any[];
  defaultValue: string;
  onChange: (tag: string) => void;
  larger?: boolean;
}

export class SelectFilter extends Component<ISelectFilterProps> {
  constructor(props: ISelectFilterProps) {
    super(props);
  }

  public render(): JSX.Element {
    const tags = this.props.items.map((option) => option.label);
    const defaultItem =
      this.props.defaultValue && this.props.items && this.props.items.length > 0
        ? this.props.items.filter(
            (item) => item.name.toLocaleLowerCase().trim() === this.props.defaultValue.toLocaleLowerCase().trim()
          )
        : null;
    const defaultValue = defaultItem && defaultItem[0] ? defaultItem[0].label.trim() : null;

    const onchanged = (labelSelected: string) => {
      const defaultkey = this.props.items.filter((item) => item.label === labelSelected)[0].name;
      this.props.onChange(defaultkey);
    };

    return (
      <ComboBox
        items={tags}
        defaultItem={defaultValue}
        onChange={(value) => onchanged(value)}
        larger={this.props.larger}
      />
    );
  }
}
