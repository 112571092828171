import URI from "urijs";
import { IApiIO } from "./typings";

export const apiRequester =
  (apiIo: IApiIO) =>
  (queryOrBody: object): Promise<Response> => {
    // const protocol = "https";
    // const soaUrl = "datamgmt-uat.fr.world.socgen/";
    // let fullUri = URI(apiIo.baseUri).absoluteTo(`${protocol}://${soaUrl}`);

    let fullUri = URI(apiIo.baseUri);
    const storageValue = retrieveAuthorizationFromLocalStorage();
    const sgConnectData = storageValue != null ? JSON.parse(storageValue) : "";

    const myHeaders = {
      Accept: "application/json",
      Authorization: "Bearer " + sgConnectData.access_token,
      "Content-Type": "application/json",
      uiscreen: "rosetta-ui",
    };

    const requestInit: RequestInit = {
      credentials: "include",
      headers: myHeaders,
      method: apiIo.method,
    };

    if (apiIo.method === "GET") {
      fullUri = fullUri.query(URI.buildQuery(queryOrBody));
    } else {
      requestInit.body = JSON.stringify(queryOrBody);
    }

    return apiIo.fetch(fullUri.valueOf(), requestInit);
  };

function retrieveAuthorizationFromLocalStorage(): string | null {
  const authorization = localStorage.getItem("SGWTConnectStorage.AUTH");
  if (authorization) {
    return authorization;
  }
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key !== null && /SGWTConnectStorage(\.\w*)?\.AUTH/g.test(key)) {
      return localStorage.getItem(key);
    }
  }
  return null;
}
