import { Reducer } from "react";
import { Params } from "components/Rosetta/params";
import { Action } from "components/Rosetta/reducers/actions";
import { AppState, INIT_ROSETTA_MESSAGE_STATE } from "../rosettaPage";

export interface FilterState {
  apiSelected: string;
  dataTypeSelected: string;
}

export const filterReducer: Reducer<AppState, Action> = (state: AppState, action: Action) => {
  if (action.type === "API_NAME_CHANGED") {
    return {
      ...state,
      apiSelected: action.payload,
      dataTypeSelected: Params.GetDataTypes(action.payload)[0].value,
      rosettaMessage: {
        ...state.rosettaMessage,
        formulakey: "",
        parameters: INIT_ROSETTA_MESSAGE_STATE.rosettaMessage.parameters,
        api: action.payload,
        datatype: Params.GetDataTypes(action.payload)[0].value,
      },
    };
  }
  if (action.type === "DATE_TYPE_CHANGED") {
    return {
      ...state,
      dataTypeSelected: action.payload,
      rosettaMessage: {
        ...state.rosettaMessage,
        formulakey: "",
        datatype: action.payload,
        parameters: INIT_ROSETTA_MESSAGE_STATE.rosettaMessage.parameters,
      },
    };
  }
  return state;
};
