import { Action } from "components/Rosetta/reducers/actions";
import { Reducer } from "react";
import { AppState } from "../rosettaPage";
import { filterReducer } from "./api.selector.reducer";
import { dateFilterReducer } from "./date.filter.reducer";
import { rosettaMessageReducer } from "./rosettaMessage.reducer";
import { uiReducer } from "./ui.reducer";

const reduceReducers =
  <S, A>(...subReducers: Array<Reducer<S, A>>) =>
  (state: S, action: A) =>
    subReducers.reduce((currentState, reducer) => reducer(currentState, action), state);

const rootReducer = reduceReducers<AppState, Action>(
  filterReducer,
  uiReducer,
  rosettaMessageReducer,
  dateFilterReducer
);

export default rootReducer;
