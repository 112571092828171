import { v4 as uniqueId } from "uuid";
import cloneDeep from "lodash";
import React from "react";
import * as PropTypes from "prop-types";

import RuleGroup from "./RuleGroup";
import { ValueEditor, ValueSelectorFilter } from "./controls/index";

export default class QueryBuilder extends React.Component<any, any> {
  static get defaultProps() {
    return {
      query: null,
      fields: [],
      operators: QueryBuilder.defaultOperators,
      combinators: QueryBuilder.defaultCombinators,
      translations: QueryBuilder.defaultTranslations,
      controlElements: null,
      getOperators: null,
      onQueryChange: null,
      controlClassnames: null,
    };
  }

  static get propTypes() {
    return {
      query: PropTypes.object,
      fields: PropTypes.array.isRequired,
      operators: PropTypes.array,
      combinators: PropTypes.array,
      controlElements: PropTypes.shape({
        fieldSelector: PropTypes.func,
        valueEditor: PropTypes.func,
      }),
      getOperators: PropTypes.func,
      onQueryChange: PropTypes.func,
      controlClassnames: PropTypes.object,
      translations: PropTypes.object,
    };
  }

  constructor(args: any) {
    super(args);
    this.state = {
      root: {},
      schema: {},
    };
  }

  static get defaultTranslations() {
    return {
      fields: {
        title: "Fields",
      },
      operators: {
        title: "Operators",
      },
      value: {
        title: "Value",
      },
      combinators: {
        title: "Combinators",
      },
    };
  }

  static get defaultOperators() {
    return [
      { name: "null", label: "Is Null" },
      { name: "notNull", label: "Is Not Null" },
      { name: "in", label: "In" },
      { name: "notIn", label: "Not In" },
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
    ];
  }

  static get defaultCombinators() {
    return [
      { name: "and", label: "AND" },
      { name: "or", label: "OR" },
    ];
  }

  static get defaultControlClassnames() {
    return {
      queryBuilder: "",
      ruleGroup: "",
      combinators: "",

      rule: "",
      fields: "",
      operators: "",
      value: "",
    };
  }

  static get defaultControlElements() {
    return {
      fieldSelector: ValueSelectorFilter,
      valueEditor: ValueEditor,
    };
  }

  public componentWillReceiveProps(nextProps: any) {
    const schema = { ...this.state.schema };

    if (this.props.query !== nextProps.query) {
      this.setState({ root: nextProps.query });
    }

    if (schema.fields !== nextProps.fields) {
      schema.fields = nextProps.fields;
      this.setState({ schema });
    }
  }

  public componentWillMount() {
    const { fields, operators, combinators, controlElements, controlClassnames } = this.props;
    const classNames = Object.assign({}, QueryBuilder.defaultControlClassnames, controlClassnames);
    const controls = Object.assign({}, QueryBuilder.defaultControlElements, controlElements);
    this.setState({
      root: this.getInitialQuery(),
      schema: {
        fields,
        operators,
        combinators,

        classNames,

        createRule: this.createRule.bind(this),
        createRuleGroup: this.createRuleGroup.bind(this),
        onRuleAdd: this._notifyQueryChange.bind(this, this.onRuleAdd),
        onGroupAdd: this._notifyQueryChange.bind(this, this.onGroupAdd),
        onRuleRemove: this._notifyQueryChange.bind(this, this.onRuleRemove),
        onGroupRemove: this._notifyQueryChange.bind(this, this.onGroupRemove),
        onPropChange: this._notifyQueryChange.bind(this, this.onPropChange),
        getLevel: this.getLevel.bind(this),
        isRuleGroup: this.isRuleGroup.bind(this),
        controls,
        getOperators: (...args: any) => this.getOperators(args),
      },
    });
  }

  public getInitialQuery() {
    return this.props.query || this.createRuleGroup();
  }

  public componentDidMount() {
    this._notifyQueryChange(null);
  }

  public render() {
    const {
      root: { id, rules, combinator },
      schema,
    } = this.state;
    const { translations } = this.props;

    return (
      <div className={`queryBuilder ${schema.classNames.queryBuilder}`} style={{ width: "100%" }}>
        <RuleGroup
          translations={translations}
          rules={rules}
          combinator={combinator}
          schema={schema}
          id={id}
          parentId={null}
        />
      </div>
    );
  }

  public isRuleGroup(rule: any) {
    return !!(rule.combinator && rule.rules);
  }

  public createRule() {
    const { fields, operators } = this.state.schema;

    return {
      id: `r-${uniqueId()}`,
      field: "",
      value: "",
      operator: operators[0].name,
    };
  }

  public createRuleGroup() {
    return {
      id: `g-${uniqueId()}`,
      rules: [],
      combinator: this.props.combinators[0].name,
    };
  }

  public getOperators(field: any) {
    if (this.props.getOperators) {
      const ops = this.props.getOperators(field);
      if (ops) {
        return ops;
      }
    }

    return this.props.operators;
  }

  public onRuleAdd(rule: any, parentId: any) {
    const parent = this._findRule(parentId, this.state.root);
    parent.rules.push(rule);

    this.setState({ root: this.state.root });
  }

  public onGroupAdd(group: any, parentId: any) {
    const parent = this._findRule(parentId, this.state.root);
    parent.rules.push(group);

    this.setState({ root: this.state.root });
  }

  public onPropChange(prop: any, value: any, ruleId: any) {
    const rule = this._findRule(ruleId, this.state.root);
    Object.assign(rule, { [prop]: value });

    this.setState({ root: this.state.root });
  }

  public onRuleRemove(ruleId: any, parentId: any) {
    const parent = this._findRule(parentId, this.state.root);
    const index = parent.rules.findIndex((x: { id: any }) => x.id === ruleId);

    parent.rules.splice(index, 1);
    this.setState({ root: this.state.root });
  }

  public onGroupRemove(groupId: any, parentId: any) {
    const parent = this._findRule(parentId, this.state.root);
    const index = parent.rules.findIndex((x: { id: any }) => x.id === groupId);

    parent.rules.splice(index, 1);
    this.setState({ root: this.state.root });
  }

  public getLevel(id: any) {
    return this._getLevel(id, 0, this.state.root);
  }

  public _getLevel(id: any, index: number, root: { id: any; rules: any[] }) {
    const { isRuleGroup } = this.state.schema;

    let foundAtIndex = -1;
    if (root.id === id) {
      foundAtIndex = index;
    } else if (isRuleGroup(root)) {
      root.rules.forEach((rule) => {
        if (foundAtIndex === -1) {
          let indexForRule = index;
          if (isRuleGroup(rule)) {
            indexForRule++;
          }
          foundAtIndex = this._getLevel(id, indexForRule, rule);
        }
      });
    }
    return foundAtIndex;
  }

  public _findRule(id: any, parent: { id: any; rules: any }) {
    const { isRuleGroup } = this.state.schema;

    if (parent.id === id) {
      return parent;
    }

    for (const rule of parent.rules) {
      if (rule.id === id) {
        return rule;
      } else if (isRuleGroup(rule)) {
        const subRule: any = this._findRule(id, rule);
        if (subRule) {
          return subRule;
        }
      }
    }
    return undefined;
  }

  public _notifyQueryChange(fn: any, ...args: undefined[]) {
    if (fn) {
      fn.call(this, ...args);
    }

    const { onQueryChange } = this.props;
    if (onQueryChange) {
      const query = cloneDeep(this.state.root);
      onQueryChange(query);
    }
  }
}
