import React from "react";
import { Icon } from "components/Rosetta/Icon";
import { useDropdownState } from "components/common/ValueSelector/useDropdownState";
import { noop } from "lodash";
import classNames from "classnames";
import { ComponentSize } from "../components.typings";
import { isStringEmpty } from "common/utils/stringUtils";
import { ClearButton } from "components/Rosetta/Buttons";

interface Props {
  displayedText?: string;
  onToggleDropDown?: (isDropDownShown: boolean) => void;
  isDropDownShown?: boolean;
  iconName?: string;
  size?: ComponentSize;
  children?: React.ReactNode;
  withOverflow?: boolean;
  placeHolder?: string;
  disabled?: boolean;
  onClearClick?: () => void;
  onInputChange?: (term: string) => void;
}

export const SelectDropDown: React.FunctionComponent<Props> = ({
  iconName,
  displayedText,
  children = null,
  isDropDownShown = false,
  onToggleDropDown = noop,
  withOverflow,
  placeHolder = "Search your value",

  onInputChange = noop,
  onClearClick = noop,
}: Props) => {
  const ref = React.useRef(null);
  const [isDropDownShownState, setToggleDropdown] = useDropdownState(ref, isDropDownShown);
  const [displayedTextState, setDisplayedTextState] = React.useState(displayedText);

  const handleOnInputClick = () => {
    onInputChange(displayedTextState!);
    if (!isDropDownShownState) {
      setToggleDropdown(!isDropDownShownState);
    }
  };

  const handleOnInputChange = (event?: any) => {
    const term = event.target.value;
    setDisplayedTextState(term);
    onInputChange(term);
  };

  const handleOnClearClick = () => {
    onClearClick();
  };

  React.useEffect(() => onToggleDropDown(isDropDownShownState), [isDropDownShownState]);

  React.useEffect(() => setDisplayedTextState(displayedText), [displayedText]);

  return (
    <div ref={ref} className={classNames("input-group", "lg")}>
      {iconName && (
        <div className="input-group-prepend input-group-merged">
          <Icon iconName={iconName} className="text-secondary" />
        </div>
      )}
      <div className={classNames("input-group-append input-group-merged")}>
        {!isStringEmpty(displayedTextState) && <ClearButton onClick={handleOnClearClick} />}
      </div>
      <input
        type="text"
        placeholder={placeHolder}
        className={"form-control"}
        value={displayedTextState}
        onChange={handleOnInputChange}
        onClick={handleOnInputClick}
      />

      {isDropDownShownState && (
        <>
          <div className={`card card-dropdown shadow-lg ${withOverflow ? "card-dropdown-overflow" : ""}`}>
            <div className="card-body p-0">{children}</div>
          </div>
        </>
      )}
    </div>
  );
};
