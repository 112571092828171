import React from "react";
import ErrorCanvas from "./ErrorCanvas";

interface ErrorState {
  hasError: boolean;
  errorMessage?: string;
}

export class ErrorHandler extends React.Component<unknown, ErrorState> {
  constructor(props: unknown) {
    super(props);
    this.state = { hasError: false, errorMessage: undefined };
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return <ErrorCanvas title={this.state.errorMessage!} />;
    }
    return this.props.children;
  }

  public componentDidCatch(error: Error, _errorInfo: React.ErrorInfo): void {
    this.setState({
      hasError: true,
      errorMessage: error ? error.message : " ",
    });
  }
}
