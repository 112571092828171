import React from "react";
import { FieldType, IColumn } from "../../../api/typings";
import QueryBuilder from "../query-builder/QueryBuilder";
import { transformRuleToQuery, addCombinator, getRule } from "./utils/helper";
import config from "./utils/config";
import { isEqual } from "lodash";
import { IOption } from "components/Rosetta/params";

interface ICprQueryBuilderProps {
  fields: IColumn[];
  operators: IOption[];
  title: string;
  query?: string;
  onQueryChanged?: (query: string) => void;
  fieldType: FieldType;
}

interface ICprQueryBuilderState {
  fields: IColumn[];
  operators: IOption[];
  query: any;
}

// TODO encapsulate jQuery Query Builder ?
// see https://github.com/mfyz/react-mongo-querybuilder/blob/master/index.html
export class CprQueryBuilder extends React.Component<ICprQueryBuilderProps, ICprQueryBuilderState> {
  constructor(props: any) {
    super(props);
    this.state = {
      fields: props.fields,
      operators: props.operators,
      query: props.query,
    };
  }

  public shouldComponentUpdate(nextProps: any, nextState: any): boolean {
    if (
      nextProps.fields !== this.state.fields ||
      nextProps.query !== nextState.query ||
      !isEqual(nextProps.operators, this.props.operators)
    ) {
      this.setState({
        fields: nextProps.fields,
        query: nextProps.query,
      });
      return true;
    }
    return false;
  }

  public render() {
    const fields = this.state.fields.map((field) => ({
      name: field.key,
      label: field.label,
      dataType: field.dataType,
      fieldType: field.fieldType,
    }));
    const operators = this.props.operators.map((operator) => ({
      name: operator.label,
      label: operator.label,
    }));

    return (
      <>
        <h6>{this.props.title}</h6>
        <div className="col-12">
          <div className="query-builder form-inline">
            <QueryBuilder
              fields={fields}
              onQueryChange={this.logQuery.bind(this)}
              query={getRule(this.props.query!, this.props.operators)}
              controlClassnames={config.styles}
              operators={operators}
              combinators={config.combinators}
            />
          </div>
        </div>
      </>
    );
  }

  public logQuery(query: any) {
    const queryObj = JSON.parse(JSON.stringify(query));
    const newQuery = transformRuleToQuery(queryObj, this.props.operators);

    const queryResult = addCombinator(queryObj, newQuery);
    this.setState({ query: queryResult });
    this.props.onQueryChanged!(queryResult);
  }
}
