import React from "react";
import { ErrorHandler } from "components/Rosetta/ErrorHandler";
import RosettaPage from "components/Rosetta/rosettaPage";
import { ToastContainer, PositionOptions } from "react-toastify";

const App: React.FC = () => (
  <ErrorHandler>
    <React.StrictMode>
      <>
        <main role="main" className="container-fluid pb-5 mb-5">
          <RosettaPage />
        </main>
        <ToastContainer hideProgressBar={true} autoClose={5000} position={"top-center" as PositionOptions} />
      </>
    </React.StrictMode>
  </ErrorHandler>
);

export default App;
