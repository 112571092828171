import { IColumn } from "api/typings";
import React from "react";
import ReactTooltip from "react-tooltip";

type FieldTooltipProps = Pick<IColumn, "description" | "example"> & { itemKey: string };

const FieldTooltip: React.FC<FieldTooltipProps> = ({ description, example, itemKey }: FieldTooltipProps) => (
  <ReactTooltip id={itemKey} aria-haspopup="true" role="example" delayShow={400} place="bottom">
    {Object.entries({ Description: description, Example: example, Id: itemKey }).map(([key, value]) =>
      value ? (
        <p key={key}>
          <span>
            <strong>{key}: </strong>
            {value}
          </span>
        </p>
      ) : null
    )}
  </ReactTooltip>
);

export default FieldTooltip;
