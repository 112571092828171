import { IMultiValueOption } from "common/component/multiselect-textbox/multiselect-textbox";
import { EnumDateFilter } from "./DateFilter";

export const DatesBetweenLabel = "Date between";

export const AttributesParamName = "attributes";

export interface IOption {
  label: string;
  value: string;
  name?: string;
  description?: string;
  regEx?: RegExp;
  splitValue?: (value: string, operator?: string) => any;
}

export enum ApiName {
  CproBi = "cpro_bi",
  CproDe = "cpro_data_extract",
}

export class Params {
  public static ApiName = {
    API_BI: ApiName.CproBi,
    DATA_EXTRACT: ApiName.CproDe,
  };

  public static ControlKeys = {
    ctrlApiName: "Api_name",
    ctrlDataType: "data_type",
    ctrlDate: "date",
  };
  public static GetApis = (): IOption[] => {
    return [
      { label: "C-Profit – Main Data", value: ApiName.CproBi },
      {
        label: "C-Profit – Extended Data",
        value: ApiName.CproDe,
      },
    ];
  };
  public static GetDateFilter = (): IOption[] => {
    return [
      { label: "YTD", value: EnumDateFilter.Ytd },
      {
        label: "Date Between",
        value: EnumDateFilter.DateBetween,
      },
    ];
  };
  public static GetDataTypes = (apiName: string): IOption[] => {
    switch (apiName) {
      case ApiName.CproBi:
        return [
          { label: "MARK VIEW", name: "SC", value: "aggregated_sc" },
          { label: "SG VIEW", name: "NBI", value: "aggregated_nbi" },
          {
            label: "MARK VIEW Bucketed",
            name: "sc",
            value: "bucketed_sc",
          },
          {
            label: "SG VIEW Bucketed",
            name: "nbi",
            value: "bucketed_nbi",
          },
        ];

      case ApiName.CproDe:
        return [
          { label: "AS OF VIEW", value: "asof" },
          { label: "Facet Bridge View", value: "facet_bridge" },
          { label: "MARK VIEW", value: "cprofit_second_db_mark" },
          { label: "SG VIEW", value: "nbi" },
        ];
      default:
        return null!;
    }
  };

  public static inListSplitValue(value: string, operator?: string) {
    const tgArray: IMultiValueOption[] = [];
    const valueArray = value.split("+");
    valueArray.forEach((item) => tgArray.push({ id: item, text: item }));
    return tgArray;
  }

  public static dateBetweenSplitValue(value: string, operator?: string) {
    return value.split(operator!, 2);
  }

  public static GetOperators = (apiName: string, parameterName: string): IOption[] => {
    if ((apiName === ApiName.CproBi || apiName === ApiName.CproDe) && parameterName === AttributesParamName) {
      return [
        {
          label: "Equal",
          value: "=",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(=)(.+)$/),
        },
        {
          label: "Not Equal",
          value: "!=",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(!=)(.+)$/),
        },
        {
          label: "Like",
          value: ":like:",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(:like:)(.+)$/),
        },
        {
          label: "Not Like",
          value: ":notlike:",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(:notlike:)(.+)$/),
        },
        {
          label: "Starts With",
          value: ":startswith:",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(:startswith:)(.+)$/),
        },
        {
          label: "Doesn't Starts With",
          value: ":notstartswith:",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(:notstartswith:)(.+)$/),
        },
        {
          label: "Ends With",
          value: ":endswith:",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(:endswith:)(.+)$/),
        },
        {
          label: "Doesn't Ends With",
          value: ":notendswith:",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(:notendswith:)(.+)$/),
        },
        {
          label: "Contains",
          value: ":contains:",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(:contains:)(.+)$/),
        },
        {
          label: "Not Contains",
          value: ":notcontains:",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(:notcontains:)(.+)$/),
        },
        {
          label: "Greater than or equals",
          value: ">=",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(>=)(.+)$/),
        },
        {
          label: "Greater than",
          value: ">",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(>)(.+)$/),
        },
        {
          label: "Less than or equals",
          value: "<=",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(<=)(.+)$/),
        },
        {
          label: "Less than",
          value: "<",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(<)(.+)$/),
        },
        {
          label: DatesBetweenLabel,
          value: apiName === ApiName.CproBi ? ":" : "#",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)([:])((\d{4}\-\d{1,2}\-\d{1,2})[:#](\d{4}\-\d{1,2}\-\d{1,2}))$/),
          splitValue: Params.dateBetweenSplitValue,
        },
        {
          label: "In the list",
          value: apiName === ApiName.CproBi ? "=" : ":",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)([=:])((([a-zA-Z0-9\s\-\_]+)?\+([a-zA-Z0-9\s\-\_]+))+)$/),
          splitValue: Params.inListSplitValue,
        },
        {
          label: "Not in the list",
          value: "!=",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)([!=])((([a-zA-Z0-9\s\-\_]+)?\+([a-zA-Z0-9\s\-\_]+))+)$/),
          splitValue: Params.inListSplitValue,
        },
      ];
    }
    if ((apiName === ApiName.CproBi || apiName === ApiName.CproDe) && parameterName === "kpis") {
      return [
        {
          label: "Equal",
          value: ":",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)([=:])(.+)$/),
        },
        {
          label: "Not Equal",
          value: "!=",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(!=)(.+)$/),
        },
        {
          label: "Greater than or equals",
          value: ">=",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(>=)(.+)$/),
        },
        {
          label: "Greater than",
          value: ">",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(>)(.+)$/),
        },
        {
          label: "Less than or equals",
          value: "<=",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(<=)(.+)$/),
        },
        {
          label: "Less than",
          value: "<",
          regEx: new RegExp(/^([a-zA-Z0-9\s\-\_]+)(<)(.+)$/),
        },
      ];
    }
    return undefined!;
  };
}
