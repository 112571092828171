import React from "react";
import { IconDelete } from "components/Rosetta/Icon";
import { Icon } from "components/common/components.typings";

interface ButtonProps {
  onClick: (e: any) => void;
  className?: string;
}

export interface ClickableButtonProps {
  onClick?: (e: any) => void;
  isInModal?: boolean;
  isModalFormValid?: boolean;
  disabled?: boolean;
  icon?: Icon;
  iconClassName?: string;
  isIconBeforeLabel?: boolean;
  className?: string;
  label: string;
}

export const ClearButton: React.FC<ButtonProps> = ({ onClick }: ButtonProps) => (
  <button type="button" className="btn btn-link px-1" onClick={onClick}>
    Clear
  </button>
);

export const DeleteRuleButton: React.FC<ButtonProps> = ({ onClick, className }: ButtonProps) => (
  <button
    type="button"
    title="Delete rule"
    className={`btn btn-outline-primary btn-sm btn-icon-text ${className}`}
    onClick={onClick}
  >
    <IconDelete />
    Delete
  </button>
);

export const DeleteRulesGroupButton: React.FC<ButtonProps> = ({ onClick, className }: ButtonProps) => (
  <button
    type="button"
    title="Delete group"
    className={`btn btn-outline-primary btn-sm btn-icon-text  ${className}`}
    onClick={onClick}
  >
    <IconDelete />
    Delete
  </button>
);

export const AddRulesGroupButton: React.FC<ButtonProps> = ({ onClick, className }: ButtonProps) => (
  <button className={`btn btn-outline-primary btn-sm ${className}`} title="Add group" onClick={onClick}>
    + Group
  </button>
);

export const AddRuleButton: React.FC<ButtonProps> = ({ onClick, className }: ButtonProps) => (
  <button className={`btn btn-outline-primary btn-sm ${className}`} title="Add rule" onClick={onClick}>
    + Rule
  </button>
);
