export default {
  styles: {
    queryBuilder: "",
    ruleGroup: "",
    combinators: "",
    addRule: "btn btn-xs btn-default",
    addGroup: "btn btn-xs btn-default",
    removeGroup: "btn btn-xs btn-default",
    // rule: "",
    fields: "",
    operators: "",
    value: "form-control",
    removeRule: "btn btn-xs btn-default",
  },

  combinators: [
    { value: "and", name: "AND", label: "AND" },
    { value: "or", name: "OR", label: "OR" },
  ],
};
