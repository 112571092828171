import { IOption } from "components/Rosetta/params";
import { v4 as uniqueId } from "uuid";
import config from "./config";

export class QueryBuilderResult {
  public id!: string;
  public combinator!: string;
  public rules!: Rules[] | QueryBuilderResult[];
}

class Rules {
  public id!: string;
  public field!: string;
  public value!: string | string[];
  public operator!: string;
}

export function getRule(query: string, operators: IOption[]) {
  if (!query) {
    return { id: `g-${uniqueId()}`, rules: [], combinator: "and" };
  }

  //#region
  const queryGroupSwappingResult = groupSwapping(query);
  const rule = transformQueryToRule(queryGroupSwappingResult.query, operators, queryGroupSwappingResult.goupList);
  return rule;
}

export function transformQueryToRule(query: string, operators: IOption[], goupList: any[]): any {
  let returnVal;
  query = characterSwapping(query);

  //#region replace AND OR group
  const regex = /^(.*?)\b(AND|OR)\[\b/gm;
  let queryTransform = query;
  queryTransform = queryTransform.replace(regex, "");
  queryTransform =
    queryTransform.indexOf("]") === queryTransform.length - 1
      ? queryTransform.substring(0, queryTransform.length - 1)
      : queryTransform;
  //#endregion

  try {
    queryTransform = characterSwapping(queryTransform);
    const elements = queryTransform.split(",");
    returnVal = {
      id: `g-${uniqueId()}`,
      rules: elements.map((element) => {
        const elementReverseSwapping = goupList.find((elem) => elem.key === element);
        if (elementReverseSwapping) {
          return transformQueryToRule(elementReverseSwapping.value, operators, goupList);
        }
        let transformedElement = element;

        if (transformedElement.split(":").length <= 2) {
          transformedElement = transformedElement.replace(":", "=");
        }

        let operatorUsed = findOperator(transformedElement, operators);
        operatorUsed = operatorUsed || operators[0];

        // Splits into fields , Operator, Value
        const extractedValues = transformedElement.replace(operatorUsed.regEx!, "$1,$2,$3");
        const splitted = extractedValues.split(",", 3);
        const value = splitted[2] ? getValue(operatorUsed, reverseCharacterSwapping(splitted[2])) : "";
        return {
          id: `r-${uniqueId()}`,
          field: splitted[0],
          value,
          operator: operatorUsed.label,
        };
      }),
      combinator: query.startsWith("OR[") ? "or" : "and",
    };
  } catch (e) {
    console.error(e);
  }
  return returnVal;
}
function findOperator(expression: string, operators: IOption[]): IOption {
  return operators.find((op) => (op.regEx ? op.regEx.exec(expression) !== null : expression.indexOf(op.value) !== -1))!;
}

export function groupSwapping(query: string) {
  const childregex = /(AND|OR)(\[([^\[\]]*?)\])+?/g;
  const goupList: Array<{ key: string; value: string }> = [];

  let childList = query.match(childregex);
  while (childList && childList[0] !== query) {
    childList.forEach((elem) => {
      if (elem !== query) {
        const key = uniqueId();
        goupList.push({ key, value: elem });
        query = query.replace(elem, key);
      }
    });

    childList = query.match(childregex);
  }
  return { query, goupList };
}

function getValue(operator: IOption, value = "") {
  return operator.splitValue ? operator.splitValue(value, operator.value) : value;
}

export function transformRuleToQuery(rules: any, operators: any[]): string {
  const groupValues = rules.rules.map((obj: { operator: any; value: any[]; field: any; combinator: string }) => {
    if (obj.operator) {
      const operatorUsed = operators.find((op) => op.label === obj.operator);
      if (obj.value instanceof Array) {
        return `${obj.field}:${obj.value.map((element) => element.replace(/,/g, "~,")).join(operatorUsed.value)}`;
      } else {
        return `${obj.field}${operatorUsed.value}${obj.value}`;
      }
    } else if (obj.combinator) {
      const combinator = config.combinators.find((comb): boolean => {
        return comb.name.toLowerCase() === obj.combinator.toLowerCase();
      })!.label;
      return `${combinator}[${transformRuleToQuery(obj, operators)}]`;
    }
  });

  return groupValues.join(",");
}

export function addCombinator(rules: QueryBuilderResult, queryResult: string): string {
  switch (rules.combinator.toLocaleLowerCase()) {
    case "or":
      return `OR[${queryResult}]`;
    case "and":
      if (queryResult.indexOf("AND[") > -1 || queryResult.indexOf("OR[") > -1) {
        return `AND[${queryResult}]`;
      }
      return queryResult;
    default:
      return queryResult;
  }
}

export function reverseCharacterSwapping(value: string): string {
  return characterSwapping(value)
    .replace(/,/gi, "~,")
    .replace(/~com~/gi, ",")
    .replace(/~plus~/gi, "+")
    .replace(/~semi~/gi, ":");
}

function characterSwapping(query: string) {
  return query.replace(/~,/gi, "~com~").replace(/~[+]/gi, "~plus~").replace(/~:/gi, "~semi~");
}
