import { IExploreIndicators } from "./typings";
import { IColumn, FieldType, IExploreDimensions } from "api/typings";

export function mapApiBI(exploreIndicators: IExploreIndicators, exploreDimensions: IExploreDimensions): IColumn[] {
  const kpis: IColumn[] = exploreIndicators.kpis.map(
    (elem) =>
      <IColumn>{
        description: elem.description,
        fieldType: FieldType.KPI,
        key: elem.id,
        label: elem.name,
      }
  );

  const attributes: IColumn[] = [];
  exploreDimensions.dimensions.forEach((attr) => {
    attr.attributes.forEach((elem) =>
      attributes.push({
        dimensionId: attr.id,
        dimensionName: attr.name,
        dimensionDescription: attr.description!,
        description: elem.description,
        fieldType: FieldType.Attribute,
        key: elem.id,
        label: elem.name,
        example: elem.example,
        dataType: elem.dataType,
      })
    );
  });
  return kpis.concat(attributes);
}
