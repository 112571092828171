import { GetApiBiFields } from "./cpro-bi/cpro-bi-api";
import { GetDataExtractFields } from "./data-extract/data-extract-api";
import { IColumn } from "./typings";
import { Params } from "components/Rosetta/params";

export async function GetFields(apiName: string, dataType: string): Promise<IColumn[]> {
  let response = null;

  switch (apiName) {
    case Params.ApiName.API_BI:
      const apiConfiguration = Params.GetDataTypes(Params.ApiName.API_BI).find(
        (elem) => elem.value === dataType
      ) as any;

      response = await GetApiBiFields(apiConfiguration.name, apiConfiguration.value.startsWith("bucketed"));
      break;
    case Params.ApiName.DATA_EXTRACT:
      response = await GetDataExtractFields(dataType);
      break;
  }
  return response!;
}
