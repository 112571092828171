import React from "react";
import MultiValueTextBox from "../../multiselect-textbox/multiselect-textbox";
import { AttributeFilterValueContainer } from "components/filter/attributes/filter.attribute.AttributeFilterValue.container";
import DatePicker from "components/Rosetta/DatePicker";
import moment from "moment";

const ValueEditor: any = (props: any) => {
  const { className, operator, value, handleOnChange, title, handleAddition, handleDelete, fields, field } = props;

  function handleFromDateChange(fromDate: string) {
    let value2 = moment(new Date()).format("YYYY-MM-DD");
    if (props.value && props.value.length > 1 && props.value[1]) {
      value2 = props.value[1];
    }
    props.handleOnChange([fromDate, value2]);
  }

  function handleToDateChange(toDate: string) {
    let value1 = moment(new Date()).format("YYYY-MM-DD");
    if (props.value && props.value.length > 0 && props.value[0]) {
      value1 = props.value[0];
    }
    props.handleOnChange([value1, toDate]);
  }

  const selectedField = fields.filter((x: any) => x.name === props.field);
  const dataType = selectedField.length > 0 ? selectedField[0].dataType : "";
  const fieldType = selectedField.length > 0 ? selectedField[0].fieldType : undefined;

  if (operator === "null" || operator === "notNull") {
    return null;
  }

  if (operator.toLowerCase().indexOf("date between") !== -1) {
    let value1 = "";
    let value2 = "";

    const setDateValueSafely = (date: string): string => {
      if (moment(date).isValid()) {
        return date;
      }
      return "";
    }

    if (typeof value === "string") {
      const valueArray = value.split(',');
      if (valueArray.length > 0) {
        value1 = setDateValueSafely(valueArray[0]);
      }
      if (valueArray.length > 1) {
        value2 = setDateValueSafely(valueArray[1]);
      }
      props.handleOnChange([value1, value2]);
    }
    else {
      if (value.length > 0) {
        value1 = setDateValueSafely(value[0]);
      }
      if (value.length > 1) {
        value2 = setDateValueSafely(value[1]);
      }
    }
    return (
      <div>
        <DatePicker date={new Date(value1)} onDataChange={handleFromDateChange} />
        <DatePicker date={new Date(value2)} onDataChange={handleToDateChange} />
      </div>
    );
  }
  if (operator.toLowerCase().indexOf("in the list") !== -1) {
    return (
      <MultiValueTextBox
        value={value}
        handleAddition={handleAddition}
        handleDelete={handleDelete}
        handleInputChange={handleOnChange}
      />
    );
  }
  if (dataType && dataType.toLowerCase() === "date") {
    return (
      <DatePicker date={new Date(value)} onDataChange={handleOnChange} timeFormat={shouldDisplayTime(props.field)} />
    );
  } else {
    return (
      <div className={className}>
        <AttributeFilterValueContainer
          onAttrValueChange={handleOnChange}
          field={field}
          title={title}
          value={value}
          fieldType={fieldType}
        />
      </div>
    );
  }
};

const shouldDisplayTime = (fieldName: string) => {
  return (
    fieldName === "markscmvt_mvtenddate" ||
    fieldName === "markscmvt_mvtstartdate" ||
    fieldName === "asof_cdfrom" ||
    fieldName === "asof_cdto" ||
    fieldName === "asof_aof" ||
    fieldName === "asof_aot" ||
    fieldName === "asof_ytdf"
  );
};

ValueEditor.displayName = "ValueEditor";

export default ValueEditor;
