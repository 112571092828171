import React from "react";
import { SelectFilter } from "../../cpr-select/selectFilter";

const ValueSelectorFilter: any = (props: any) => {
  const { value, options, handleOnChange, larger } = props;

  return <SelectFilter items={options} defaultValue={value} onChange={handleOnChange} larger={larger} />;
};

ValueSelectorFilter.displayName = "ValueSelectorFilter";

export default ValueSelectorFilter;
