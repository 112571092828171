export const convertToCancellable = (promise: Promise<any>): CancellablePromise<any> => {
  const isCancelled = { value: false };
  const wrappedPromise = new Promise((res, rej) => {
    promise
      .then((d) => {
        return isCancelled.value ? rej(isCancelled) : res(d);
      })
      .catch((e) => {
        rej(isCancelled.value ? isCancelled : e);
      });
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCancelled.value = true;
    },
  };
};

export interface CancellablePromise<T> {
  promise: Promise<T>;
  cancel: () => void;
}
