import React from "react";
import { SortableList } from "../../common/component/sortable-list/sortable-list";
import { CprQueryBuilder } from "../../common/component/cpr-query-builder/cpr-query-builder";
import { orderBy } from "lodash";
import { IOption, Params } from "components/Rosetta/params";
import DropDownList from "../../common/component/DropDownList";
import { GetFields } from "api/apiServices";
import { FieldType, IColumn } from "../../api/typings";
import { Loading } from "components/Rosetta/Loading";
import { FormField } from "components/common/ReadOnlyField/FormField";
import { isStringEmpty, isStringEqual } from "common/utils/stringUtils";
import { CancellablePromise, convertToCancellable } from "./cancellablePromise";
import DateFilter from "components/Rosetta/DateFilter";

export interface IApiChooserProps {
  kpiSelected: string[];
  attributesSelected: string[];
  filter: string;
  kpifilter: string;
  apiName: string;
  dateString: string;
  apiSelected: string;
  dataType: string;
  onKpiChanged: (kpiList: string[]) => void;
  onAttrChanged: (attrList: string[]) => void;
  onAttrFilterChanged: (filter: string) => void;
  onKpiFilterChanged: (filter: string) => void;
  onDateChange: (newDate: string) => void;
}

export interface IApiChooserState {
  kpisList: IColumn[];
  attributesList: IColumn[];
  dimensionsList: IOption[];
  kpis: IColumn[];
  attributes: IColumn[];
  kpiSelected: IColumn[];
  attributesSelected: IColumn[];
  dimensionSelected: string;
  dimensionDescription: string;

  loading: boolean;
}

export class ApiChooser extends React.Component<IApiChooserProps, IApiChooserState> {
  private runningPromise!: CancellablePromise<IColumn[]>;

  constructor(props: IApiChooserProps | Readonly<IApiChooserProps>) {
    super(props);
    this.state = {
      attributesList: [],
      attributes: [],
      attributesSelected: [],
      kpisList: [],
      kpiSelected: [],
      kpis: [],
      dimensionsList: [],
      dimensionSelected: "",
      dimensionDescription: "",
      loading: true,
    };
  }

  public async componentDidMount() {
    this.fetchApi();
  }

  public componentDidUpdate(prevProps: IApiChooserProps) {
    if (prevProps.apiName !== this.props.apiName || prevProps.dataType !== this.props.dataType) {
      this.setState({
        loading: true,
        dimensionSelected: "All",
        dimensionDescription: "",
      });
      this.fetchApi();
    }
    if (
      prevProps.attributesSelected !== this.props.attributesSelected ||
      prevProps.kpiSelected !== this.props.kpiSelected
    ) {
      this.propsUpdated();
    }
  }

  public render(): JSX.Element {
    return (
      <>
        <Loading isVisible={this.state.loading} />
        {!this.state.loading && (
          <>
            <div className="row">
              <div className="col-12 ">
                <div style={{ width: "260px" }}>
                  <FormField id={"dimensionName"} label={"Dimension"}>
                    <DropDownList
                      onChange={this.OnDimensionChange.bind(this)}
                      tags={this.state.dimensionsList}
                      selected={this.state.dimensionSelected}
                    />
                  </FormField>
                  <p className={"text-wrap"}>{this.state.dimensionDescription}</p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <h6>Attributes</h6>

                <SortableList
                  items={this.state.attributes}
                  itemsSelected={this.state.attributesSelected}
                  onSelectItem={this.OnSelectAttr.bind(this)}
                  onUnSelectItem={this.OnUnSelectAttr.bind(this)}
                  onOrderChanged={this.OnAttrOrderChanger.bind(this)}
                />
              </div>
              <div className="col-12 col-md-6">
                <h6>KPIs</h6>
                <SortableList
                  items={this.state.kpis}
                  itemsSelected={this.state.kpiSelected}
                  onSelectItem={this.OnSelectKpi.bind(this)}
                  onUnSelectItem={this.OnUnSelectKpi.bind(this)}
                  onOrderChanged={this.OnKPIOrderChanger.bind(this)}
                />
              </div>
            </div>
            <hr />
            <h6>Choose your filters</h6>

            <DateFilter
              dateString={this.props.dateString}
              apiName={this.props.apiSelected}
              onDateChange={this.props.onDateChange}
            />

            <CprQueryBuilder
              title={"Attributes"}
              fields={this.state.attributesList}
              operators={Params.GetOperators(this.props.apiName, "attributes")}
              query={this.props.filter}
              onQueryChanged={this.OnAttrQueryChanged.bind(this)}
              fieldType={FieldType.Attribute}
            />
            <br />
            <CprQueryBuilder
              title={"Kpis"}
              fields={this.state.kpisList}
              operators={Params.GetOperators(this.props.apiName, "kpis")}
              query={this.props.kpifilter}
              onQueryChanged={this.OnKpiQueryChanged.bind(this)}
              fieldType={FieldType.KPI}
            />
          </>
        )}
      </>
    );
  }

  private fetchApi() {
    if (this.runningPromise !== undefined) {
      this.runningPromise.cancel();
    }

    this.runningPromise = convertToCancellable(GetFields(this.props.apiName, this.props.dataType));
    this.runningPromise.promise.then((data) => {
      const dataOrdred = orderBy(data, "label", "asc");
      this.setState({
        loading: false,
        dimensionsList: this.getDimensionsList(dataOrdred),
        attributesList: dataOrdred.filter((elem) => elem.fieldType === FieldType.Attribute),
        attributes: dataOrdred.filter(
          (elem) =>
            elem.fieldType === FieldType.Attribute &&
            this.props.attributesSelected.findIndex(
              (elemSelected) => elemSelected.toLocaleLowerCase() === elem.key.toLocaleLowerCase()
            ) < 0
        ),
        attributesSelected: dataOrdred.filter(
          (elem) =>
            elem.fieldType === FieldType.Attribute &&
            this.props.attributesSelected.findIndex(
              (elemSelected) => elemSelected.toLocaleLowerCase() === elem.key.toLocaleLowerCase()
            ) > -1
        ),
        kpiSelected: dataOrdred.filter(
          (elem) =>
            elem.fieldType === FieldType.KPI &&
            this.props.kpiSelected.findIndex(
              (elemSelected) => elemSelected.toLocaleLowerCase() === elem.key.toLocaleLowerCase()
            ) > -1
        ),
        kpis: dataOrdred.filter(
          (elem) =>
            elem.fieldType === FieldType.KPI &&
            this.props.kpiSelected.findIndex(
              (elemSelected) => elemSelected.toLocaleLowerCase() === elem.key.toLocaleLowerCase()
            ) < 0
        ),
        kpisList: dataOrdred.filter((elem) => elem.fieldType === FieldType.KPI),
      });
    });
  }

  private propsUpdated() {
    const { attributes, attributesList, kpisList } = this.state;

    this.setState({
      attributes: attributes.filter(
        (elem) =>
          elem.fieldType === FieldType.Attribute &&
          this.props.attributesSelected.findIndex(
            (elemSelected) => elemSelected.toLocaleLowerCase() === elem.key.toLocaleLowerCase()
          ) < 0
      ),
      attributesSelected: attributesList
        .filter(
          (elem) =>
            elem.fieldType === FieldType.Attribute &&
            this.props.attributesSelected.findIndex(
              (elemSelected) => elemSelected.toLocaleLowerCase() === elem.key.toLocaleLowerCase()
            ) > -1
        )
        .sort((a, b) => this.props.attributesSelected.indexOf(a.key) - this.props.attributesSelected.indexOf(b.key)),
      kpis: kpisList.filter(
        (elem) =>
          elem.fieldType === FieldType.KPI &&
          this.props.kpiSelected.findIndex(
            (elemSelected) => elemSelected.toLocaleLowerCase() === elem.key.toLocaleLowerCase()
          ) < 0
      ),
      kpiSelected: kpisList
        .filter(
          (elem) =>
            elem.fieldType === FieldType.KPI &&
            this.props.kpiSelected.findIndex(
              (elemSelected) => elemSelected.toLocaleLowerCase() === elem.key.toLocaleLowerCase()
            ) > -1
        )
        .sort((a, b) => this.props.kpiSelected.indexOf(a.key) - this.props.kpiSelected.indexOf(b.key)),
    });
  }

  private OnSelectKpi(item: string, position: number) {
    const kpiSelected = this.state.kpiSelected;
    const itemSelected = this.state.kpis.filter((element) => element.key === item)[0];
    if (itemSelected && kpiSelected.filter((element) => element.key === item).length === 0) {
      const kpis = this.state.kpis.filter((element) => element.key !== item);
      kpiSelected.splice(position, 0, itemSelected);
      this.setState({ kpis, kpiSelected });
      this.props.onKpiChanged(kpiSelected.map((elem) => elem.key));
    }
  }

  private OnUnSelectKpi(item: string, position: number) {
    const kpis = this.state.kpis;
    const itemUnSelected = this.state.kpiSelected.filter((element) => element.key === item)[0];
    if (itemUnSelected && kpis.filter((element) => element.key === item).length === 0) {
      const kpiSelected = this.state.kpiSelected.filter((element) => element.key !== item);
      kpis.splice(position, 0, itemUnSelected);
      this.setState({ kpis, kpiSelected });
      this.props.onKpiChanged(kpiSelected.map((elem) => elem.key));
    }
  }

  private OnSelectAttr(item: string, position: number) {
    const { attributesSelected, attributes } = this.state;

    const itemSelected = attributes.filter((element) => element.key === item)[0];

    if (itemSelected && attributesSelected.filter((element) => element.key === item).length === 0) {
      const attrs = attributes.filter((element) => element.key !== item);

      attributesSelected.splice(position, 0, itemSelected);
      this.setState({
        attributes: attrs,
        attributesSelected,
      });

      this.props.onAttrChanged(this.state.attributesSelected.map((elem) => elem.key));

      this.props.onAttrChanged(attributesSelected.map((elem) => elem.key));
    }
  }

  private OnUnSelectAttr(item: string, position: number) {
    const { attributes, attributesSelected, dimensionSelected } = this.state;

    const itemUnSelected = attributesSelected.filter((element) => element.key === item)[0];
    if (itemUnSelected && attributes.filter((element) => element.key === item).length === 0) {
      const attrsSelected = attributesSelected.filter((element) => element.key !== item);

      attributes.splice(position, 0, itemUnSelected);

      const attrs =
        isStringEmpty(dimensionSelected) || isStringEqual(dimensionSelected, "All", true, true)
          ? attributes
          : attributes.filter((x) => x.dimensionId === dimensionSelected);

      this.setState({
        attributes: attrs,
        attributesSelected: attrsSelected,
      });

      this.props.onAttrChanged(attrsSelected.map((elem) => elem.key));
    }
  }

  private OnAttrOrderChanger(items: IColumn[]) {
    this.props.onAttrChanged(items.map((elem) => elem.key));
  }

  private OnKPIOrderChanger(items: IColumn[]) {
    this.props.onKpiChanged(items.map((elem) => elem.key));
  }

  private OnAttrQueryChanged(filter: string) {
    this.props.onAttrFilterChanged(filter);
  }

  private OnKpiQueryChanged(filter: string) {
    this.props.onKpiFilterChanged(filter);
  }

  private getDimensionsList(dataOrdred: IColumn[]): IOption[] {
    const dimensionList: IOption[] = [];
    dimensionList.push({ value: "All", label: "All" });

    const dimensionFiltered: IColumn[] = dataOrdred
      .filter((elem) => elem.fieldType === FieldType.Attribute || elem.fieldType === FieldType.Dimension)
      .filter((thing, i, arr) => arr.findIndex((t) => t.dimensionId === thing.dimensionId) === i);
    dimensionFiltered.forEach((elem) =>
      dimensionList.push({
        value: elem.dimensionId,
        label: elem.dimensionName,
        description: elem.dimensionDescription,
      })
    );
    return dimensionList;
  }

  private OnDimensionChange(dimensionSelected: string) {
    const { attributesList, attributesSelected } = this.state;
    let attrFiltered: IColumn[] = [];
    if (dimensionSelected === "All") {
      attrFiltered = attributesList.filter(
        (elem) => elem.fieldType === FieldType.Attribute && attributesSelected.findIndex((x) => x.key === elem.key) < 0
      );
    } else {
      attrFiltered = attributesList.filter(
        (elem) =>
          elem.fieldType === FieldType.Attribute &&
          elem.dimensionId === dimensionSelected &&
          attributesSelected.findIndex((x) => x.key === elem.key) < 0
      );
    }
    const dimensionDescription = this.state.dimensionsList.find((elem) => elem.value === dimensionSelected)!
      .description!;

    this.setState({
      dimensionDescription,
      dimensionSelected,
      attributes: attrFiltered,
    });
  }
}
