import React from "react";
import { AttributeValuesApiCall } from "../../../api/cpro-bi/cpro-bi-api";
import { ValueSelector } from "components/common/ValueSelector/ValueSelector";
import { map, includes } from "lodash";
import { ApiName, Params } from "components/Rosetta/params";
import { FieldType } from "api/typings";

export interface DispatchProps {
  onAttrValueChange: (value: string) => void;
  field: string;
  title: string;
  value: string;
  fieldType: FieldType;
}

export interface IStateProps {
  dataTypeSelected: string;
  apiSelected: string;
}

interface IState {
  attrValues: string[];
  isFetching: boolean;
}

export class AttributeFilterValue extends React.Component<IStateProps & DispatchProps, IState> {
  constructor(props: IStateProps & DispatchProps) {
    super(props);
    this.state = {
      attrValues: [],
      isFetching: false,
    };

    this.handleOnValueChange = this.handleOnValueChange.bind(this);
  }

  public componentWillReceiveProps(nextProps: DispatchProps & IStateProps) {
    if (
      !this.isDataExtractDataType(nextProps.dataTypeSelected) &&
      nextProps.fieldType !== FieldType.KPI &&
      ((nextProps.field !== "" && this.props.field !== nextProps.field) ||
        (nextProps.dataTypeSelected !== "" && this.props.dataTypeSelected !== nextProps.dataTypeSelected && !test))
    ) {
      this.fetchAttributeValues(nextProps);
    }
  }

  public render() {
    if (this.props.apiSelected === ApiName.CproBi) {
      const { attrValues } = this.state;
      return (
        <div className={"col-12 p-0"}>
          <ValueSelector
            selectedItem={this.props.value}
            items={attrValues}
            onChanged={this.handleOnValueChange}
            closeOnSelect={false}
            placeHolder={"Search your value"}
          />
        </div>
      );
    } else {
      return (
        <div className={"col-12 p-0"}>
          <input
            type="text"
            value={this.props.value}
            title={this.props.title}
            className={"form-control"}
            onChange={(e) => this.props.onAttrValueChange(e.target.value)}
          />
        </div>
      );
    }
  }

  private handleOnValueChange(value: string) {
    this.props.onAttrValueChange(value);
  }

  private async fetchAttributeValues(props: DispatchProps) {
    this.setState({ isFetching: true });
    const dataTypeSplit = this.props.dataTypeSelected.split("aggregated_");
    if (dataTypeSplit.length > 0) {
      const attrValues = await AttributeValuesApiCall(dataTypeSplit[1], props.field);

      this.setState({
        attrValues: attrValues.values!,
        isFetching: false,
      });
    }
  }

  private readonly isDataExtractDataType = (dataType: string): boolean =>
    includes(
      map(Params.GetDataTypes(ApiName.CproDe), (item) => item.value),
      dataType
    );
}
