import { IExploreDimensions, IExploreKpis } from "./typings";
import { IColumn, FieldType } from "api/typings";

export function mapDataExtract(explorekpis: IExploreKpis, exploreDimension: IExploreDimensions): IColumn[] {
  const kpis: IColumn[] = explorekpis.kpis.map(
    (elem) =>
      ({
        description: elem.id,
        fieldType: FieldType.KPI,
        key: elem.id,
        label: elem.name,
        example: elem.example,
      } as IColumn)
  );

  const attributes: IColumn[] = [];

  exploreDimension.dimensions.forEach((attr) => {
    attr.attributes.forEach((elem) =>
      attributes.push({
        dimensionId: attr.id,
        dimensionName: attr.name,
        dimensionDescription: attr.description!,
        description: elem.description,
        fieldType: FieldType.Attribute,
        key: elem.id,
        label: elem.name,
        example: elem.example,
        dataType: elem.dataType,
      })
    );
  });

  return kpis.concat(attributes);
}
