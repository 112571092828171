import React from "react";
import { ApiName, Params } from "./params";
import { ApiChooser } from "components/columnsSelector/columns.selector";
import { toastifySuccess, toastifyError } from "components/Rosetta/toasterNotifyer";
import { FilterState } from "./reducers/api.selector.reducer";
import rootReducer from "./reducers/root.reducer";
import { apiNameChangeAction, dataTypeChangeAction } from "components/Rosetta/reducers/actions";
import { UiState } from "./reducers/ui.reducer";
import moment from "moment";
import { RosettaMessage, RosettaMessageState } from "./reducers/rosettaMessage.reducer";
import ApiFilters from "./ApiFilters";
import QueryResult from "./QueryResult";
import ActionButtons from "./ActionButtons";

// Note that those are functions injected by Excel.
declare let SetExcelValue: any;
declare let Ready: any;

export type AppState = FilterState & UiState & RosettaMessageState;

const INIT_FILTER_STATE: FilterState = {
  apiSelected: "cpro_bi",
  dataTypeSelected: "aggregated_sc",
};

const INIT_UI_STATE: UiState = {
  hideResult: true,
  showResultText: "Show query result",
  hasError: false,
};

export const INIT_ROSETTA_MESSAGE_STATE: RosettaMessageState = {
  rosettaMessage: {
    api: "cpro_bi",
    datatype: "aggregated_sc",
    formulakey: "",
    parameters: {
      attributefilter: "",
      filter: "",
      attributes: "",
      date: moment(new Date()).format("YYYY-MM-DD"),
      kpifilter: "",
      kpis: "",
    },
  },
};

const initRosettaMessage = (): RosettaMessage => {
  const result = window.localStorage.getItem("rosettaMessage");
  if (result) {
    const rosettaMessage = JSON.parse(result) as RosettaMessage;
    window.localStorage.removeItem("rosettaMessage");
    if (rosettaMessage) {
      return rosettaMessage;
    }
  }
  return INIT_ROSETTA_MESSAGE_STATE.rosettaMessage;
};

const INIT_STATE: AppState = {
  ...INIT_FILTER_STATE,
  ...INIT_UI_STATE,
  ...INIT_ROSETTA_MESSAGE_STATE,
};

export const StateContext = React.createContext(INIT_STATE);

const RosettaPage: React.FC = () => {
  const [state, dispatch] = React.useReducer(rootReducer, INIT_STATE);

  const attrListChange = (attrList: string[]) => {
    dispatch({
      type: "ROSETTA_MESSAGE_CHANGE",
      payload: {
        ...state.rosettaMessage,
        parameters: {
          ...state.rosettaMessage.parameters,
          attributes: attrList.join(","),
        },
      },
    });
  };

  const kpiListChange = (kpiList: string[]) => {
    dispatch({
      type: "ROSETTA_MESSAGE_CHANGE",
      payload: {
        ...state.rosettaMessage,
        parameters: {
          ...state.rosettaMessage.parameters,
          kpis: kpiList.join(","),
        },
      },
    });
  };

  const attrFilterChange = (filter: string) => {
    switch (state.rosettaMessage.api) {
      case Params.ApiName.API_BI:
        dispatch({
          type: "ROSETTA_MESSAGE_CHANGE",
          payload: {
            ...state.rosettaMessage,
            parameters: {
              ...state.rosettaMessage.parameters,
              attributefilter: filter,
            },
          },
        });
        break;
      case Params.ApiName.DATA_EXTRACT:
        dispatch({
          type: "ROSETTA_MESSAGE_CHANGE",
          payload: {
            ...state.rosettaMessage,
            parameters: {
              ...state.rosettaMessage.parameters,
              filter,
            },
          },
        });
        break;
    }
  };

  const kpiFilterChange = (filter: string) => {
    dispatch({
      type: "ROSETTA_MESSAGE_CHANGE",
      payload: {
        ...state.rosettaMessage,
        parameters: {
          ...state.rosettaMessage.parameters,
          kpifilter: filter,
        },
      },
    });
  };

  const OnReset = () => {
    try {
      const defaultRosetta: RosettaMessage = {
        api: "cpro_bi",
        datatype: "aggregated_sc",
        formulakey: "",
        parameters: {
          attributefilter: "",
          filter: "",
          attributes: "",
          date: "",
          kpifilter: "",
          kpis: "",
        },
      };

      dispatch({
        type: "ROSETTA_MESSAGE_CHANGE",
        payload: defaultRosetta,
      });

      toastifySuccess("Success : Reset query done");
    } catch (error: any) {
      toastifyError(error);
      // logerror
    }
  };

  const showResultBlock = () => {
    try {
      if (state.hideResult) {
        dispatch({ type: "UPDATE_RESULT_MESSAGE", payload: { message: "Hide query result", hide: false } });
      } else {
        dispatch({ type: "UPDATE_RESULT_MESSAGE", payload: { message: "Display query result", hide: true } });
      }
    } catch (error) {
      dispatch({ type: "ERROR" });
    }
  };

  const generateFileOnClick = () => {
    try {
      const rosettaToSend = {
        ...state.rosettaMessage,
        parameters:
          state.rosettaMessage.api === ApiName.CproBi
            ? {
                attributes: state.rosettaMessage.parameters.attributes,
                attributefilter: state.rosettaMessage.parameters.attributefilter,
                kpis: state.rosettaMessage.parameters.kpis,
                kpifilter: state.rosettaMessage.parameters.kpifilter,
                date: state.rosettaMessage.parameters.date,
              }
            : {
                attributes: state.rosettaMessage.parameters.attributes,
                filter: state.rosettaMessage.parameters.filter,
                kpis: state.rosettaMessage.parameters.kpis,
                kpifilter: state.rosettaMessage.parameters.kpifilter,
                date: state.rosettaMessage.parameters.date,
              },
      };

      const dataToSend = JSON.stringify(rosettaToSend);
      if (typeof SetExcelValue !== "undefined") {
        SetExcelValue(dataToSend);
      }
      toastifySuccess("Send query to rosetta done");
    } catch (error: any) {
      toastifyError("Error: " + error.message);
      dispatch({ type: "ERROR" });
    }
  };

  const receiveMessage = (ev: any) => {
    if (ev.detail !== undefined && ev.detail !== null && ev.detail !== "") {
      const rosettaMessage = JSON.parse(ev.detail) as RosettaMessage;
      if (rosettaMessage === null || rosettaMessage === undefined) {
        OnReset();
      }
      dispatch({
        type: "ROSETTA_MESSAGE_CHANGE",
        payload: rosettaMessage,
      });

      toastifySuccess("Rosetta query received");
    } else if (ev.detail === "") {
      OnReset();
    } else {
      toastifyError("Error: receive rosetta query failed !!");
    }
  };

  React.useEffect(() => {
    dispatch({
      type: "ROSETTA_MESSAGE_CHANGE",
      payload: initRosettaMessage(),
    });

    window.addEventListener("rosettaMessagechanged", receiveMessage);
    if (typeof Ready !== "undefined") {
      Ready();
    }
    return () => window.removeEventListener("rosettaMessagechanged", receiveMessage);
  }, []);
  return (
    <>
      <div className="row text-info p-0 pl-2 pb-2">
        <div className="text-info p-0">
          <i className="icon icon-md line-height-1 mr-2">info</i>
          You can now schedule your Rosetta queries and receive the result by email. Visit <b>go/rosetta</b> page to
          learn more.
        </div>
      </div>
      <ApiFilters
        apiSelected={state.apiSelected}
        dataTypeSelected={state.dataTypeSelected}
        onApiNameChange={(apiName) => dispatch(apiNameChangeAction(apiName))}
        onDataTypeChange={(dataType) => dispatch(dataTypeChangeAction(dataType))}
      />
      <hr className="mb-1 mt-1" />
      <div>
        <h6>Choose your columns</h6>
        <StateContext.Provider value={state}>
          <ApiChooser
            attributesSelected={state.rosettaMessage.parameters.attributes.split(",")}
            apiSelected={state.apiSelected}
            dateString={state.rosettaMessage.parameters.date}
            kpiSelected={state.rosettaMessage.parameters.kpis.split(",")}
            apiName={state.rosettaMessage.api}
            dataType={state.rosettaMessage.datatype}
            filter={
              state.rosettaMessage.api === Params.ApiName.API_BI
                ? state.rosettaMessage.parameters.attributefilter
                : state.rosettaMessage.parameters.filter
            }
            kpifilter={state.rosettaMessage.parameters.kpifilter}
            onKpiChanged={kpiListChange}
            onAttrChanged={attrListChange}
            onAttrFilterChanged={attrFilterChange}
            onKpiFilterChanged={kpiFilterChange}
            onDateChange={(date) => dispatch({ type: "DATE_CHANGED", payload: date })}
          />
        </StateContext.Provider>

        {!state.hideResult && <QueryResult queryResult={state.rosettaMessage} />}
      </div>
      <div
        className="card-body pull-right col pt-0"
        style={{
          position: "fixed",
          bottom: "0.5%",
          left: 0,
          width: "100%",
        }}
      >
        <div className="col">
          <div className="form-group mb-0">
            <div className="btn-group float-right" role="group">
              <ActionButtons showResult={showResultBlock} reset={OnReset} apply={generateFileOnClick} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RosettaPage;
