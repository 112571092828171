import { IOption } from "components/Rosetta/params";
import React from "react";

interface DropDownListProps {
  onChange: (selected: string) => void;
  tags: IOption[];
  selected: string;
  className?: string;
}

const DropDownList: React.FC<DropDownListProps> = ({ tags, selected, onChange, className }: DropDownListProps) => (
  <select
    className={`form-control ${className}`}
    data-testid="select"
    onChange={(event) => onChange(event.target.value)}
    value={selected}
  >
    {tags?.map(({ label, value }, index) => (
      <option key={`${index}_${value}`} value={value}>
        {label}
      </option>
    ))}
  </select>
);

export default DropDownList;
