import { Action } from "components/Rosetta/reducers/actions";
import { Reducer } from "react";
import { AppState } from "../rosettaPage";

export const dateFilterReducer: Reducer<AppState, Action> = (state: AppState, action: Action) => {
  if (action.type === "DATE_CHANGED") {
    return {
      ...state,
      dateSelected: action.payload,
      rosettaMessage: {
        ...state.rosettaMessage,
        parameters: {
          ...state.rosettaMessage.parameters,
          date: action.payload,
        },
      },
    };
  }
  return state;
};
