import { RosettaMessage } from "./rosettaMessage.reducer";

export type Action = FilterAction | MessageAction | ErrorAction | ResultAction;

interface FilterAction {
  payload: string;
  type: "DATE_CHANGED" | "API_NAME_CHANGED" | "DATE_TYPE_CHANGED";
}

interface MessageAction {
  payload: RosettaMessage;
  type: "ROSETTA_MESSAGE_CHANGE";
}

interface ErrorAction {
  type: "ERROR";
}

interface ResultAction {
  payload: { message: string; hide: boolean };
  type: "UPDATE_RESULT_MESSAGE";
}

export const dateFilterChangeAction = (payload: string): Action => ({
  payload,
  type: "DATE_CHANGED",
});

export const apiNameChangeAction = (payload: string): Action => ({
  payload,
  type: "API_NAME_CHANGED",
});

export const dataTypeChangeAction = (payload: string): Action => ({
  payload,
  type: "DATE_TYPE_CHANGED",
});

export const rosettaMessageChangeAction = (payload: RosettaMessage): Action => ({
  type: "ROSETTA_MESSAGE_CHANGE",
  payload,
});
