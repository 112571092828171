import moment from "moment";
import React from "react";
import ReactDatetime from "react-datetime";
import ReactDOM from "react-dom";
import { uniqueId, noop } from "lodash";

export interface DateFilterProps {
  date: Date;
  timeFormat?: boolean;
  onDataChange: (newValue: string) => void;
}

interface IDateFilterState {
  date: Date;
  isOpen: boolean;
}

export default class DatePicker extends React.Component<DateFilterProps, IDateFilterState> {
  public id: string;

  constructor(props: DateFilterProps) {
    super(props);
    this.id = uniqueId();
    this.state = {
      date: this.GetInitialValue(),
      isOpen: false,
    };
  }

  public componentDidUpdate(prevProps: DateFilterProps) {
    if (this.props.date !== prevProps.date) {
      this.setState({ date: this.GetInitialValue() });
    }
  }

  public render(): JSX.Element {
    return (
      <>
        <div id={this.id} className="input-group lg  mb-3">
          <div
            className="input-group"
            style={{
              width: "190px",
            }}
          >
            <ReactDatetime
              className="w-100"
              defaultValue={new Date()}
              value={this.state.date}
              onFocus={this.onFocus}
              closeOnSelect={false}
              dateFormat="YYYY-MM-DD"
              timeFormat={this.props.timeFormat ? "HH:mm:ss" : false}
              onChange={this.handleDateChange}
              open={this.state.isOpen}
              utc={this.props.timeFormat ? false : true}
            />
            <div className="input-group-append input-group-merged">
              <button
                className={`btn btn-danger`}
                style={{ opacity: "unset", cursor: "unset" }}
                onClick={this.ClickIcon.bind(this)}
              >
                <i className="fa fa-calendar-o" />
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  private outsideClick = (event: any) => {
    const domNode = ReactDOM.findDOMNode(this);
    let format = "YYYY-MM-DD";
    if (!domNode || !domNode.contains(event.target)) {
      if (this.props.timeFormat) {
        format = "YYYY-MM-DD HH:mm:ss";
      }
      const toDateString = moment(this.state.date).format(format);
      this.props.onDataChange(toDateString);
      this.setState({
        ...this.state,
        isOpen: false,
      });
      document.removeEventListener("mousedown", this.outsideClick);
    }
    return true;
  };

  private readonly handleDateChange = (newDate: any): void => {
    this.setState({
      date: newDate,
      isOpen: true,
    });
  };

  private readonly onFocus = (_: any) => {
    document.addEventListener("mousedown", this.outsideClick);
  };

  private GetInitialValue(): Date {
    return this.IsDateInvalid() ? new Date() : this.props.date;
  }

  private IsDateInvalid() {
    return this.props.date.toString() === "Invalid Date";
  }

  private readonly ClickIcon = () => {
    this.setState({
      date: this.state.date,
      isOpen: !this.state.isOpen,
    });
    this.onFocus(noop);
  };
}
