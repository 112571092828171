import React from "react";
import moment from "moment";
import { FormField } from "components/common/ReadOnlyField/FormField";
import DropDownList from "common/component/DropDownList";
import DatePicker from "./DatePicker";
import { AttributesParamName, DatesBetweenLabel, Params } from "./params";
import { format } from "date-fns";

export enum EnumDateFilter {
  Ytd = "ytd",
  DateBetween = "date_between",
}

interface DateYtdState {
  type: "ytd";
  dateString: string;
}
interface DateRangeState {
  type: "date_between";
  startDateString: string;
  endDateString: string;
}

type DateFilterState = DateYtdState | DateRangeState;

interface DateFilterProps {
  apiName: string;
  dateString: string; // YYYY-MM-dd or  YYYY-MM-dd(:/#)YYYY-MM-dd
  onDateChange: (dateQuery: string) => void;
}

export const defaultDate = (): string => moment(new Date()).format("YYYY-MM-DD");

const firstDayOfMonth = (): string => {
  const today = new Date();
  return moment(new Date(today.getFullYear(), today.getMonth(), 1)).format("YYYY-MM-DD");
};

const getStartDate = (state: DateFilterState): string =>
  state.type === "ytd" ? state.dateString : state.startDateString;

function getGetDateBwSeparator(apiName: string): string {
  return Params.GetOperators(apiName, AttributesParamName).find((x) => x.label === DatesBetweenLabel)!.value;
}

function mapInputDateToState(dateString: string): DateFilterState {
  const dates = dateString.split(/[:#]/);
  return dates.length === 1
    ? { type: "ytd", dateString: dates[0] || defaultDate() }
    : { type: "date_between", startDateString: dates[0] || defaultDate(), endDateString: dates[1] || defaultDate() };
}

function mapStateToDateQuery(state: DateFilterState, apiName: string): string {
  return state.type === "ytd"
    ? state.dateString
    : `${state.startDateString}${getGetDateBwSeparator(apiName)}${state.endDateString}`;
}

const DateFilter: React.FC<DateFilterProps> = ({ apiName, dateString, onDateChange }: DateFilterProps) => {
  if (dateString === undefined) {
    dateString = format(new Date(), "yyyy-MM-dd");
  }
  
  const [state, setState] = React.useState<DateFilterState>(mapInputDateToState(dateString));

  React.useEffect(() => {
    setState(mapInputDateToState(dateString));
  }, [dateString]);

  React.useEffect(() => {
    onDateChange(mapStateToDateQuery(state, apiName));
  }, [state]);

  const handleDateFilterChange = (type: EnumDateFilter) => {
    if (type === EnumDateFilter.Ytd) {
      setState({
        type: "ytd",
        dateString: getStartDate(state),
      });
    }
    if (type === EnumDateFilter.DateBetween) {
      setState({ type: "date_between", startDateString: firstDayOfMonth(), endDateString: defaultDate() });
    }
  };

  const handleStartDateChange = (date: string): void => {
    setState({
      ...state,
      [state.type === "ytd" ? "dateString" : "startDateString"]: date,
    });
  };

  const handleEndDateChange = (endDateString: string): void => {
    if (state.type === "date_between") {
      setState({ ...state, endDateString });
    }
  };

  return (
    <>
      <h6>Date</h6>
      <div className="row px-2">
        <FormField id="dateType" className="col-4 col-lg-3">
          <DropDownList onChange={handleDateFilterChange} tags={Params.GetDateFilter()} selected={state.type} />
        </FormField>
        <FormField id="firstDate" className="col-4 col-lg-3">
          <DatePicker date={new Date(getStartDate(state))} onDataChange={handleStartDateChange} />
        </FormField>
        {state.type === "date_between" && (
          <FormField id="secondDate" className="col-4 col-lg-3">
            <DatePicker date={new Date(state.endDateString)} onDataChange={handleEndDateChange} />
          </FormField>
        )}
      </div>
    </>
  );
};

export default DateFilter;
