import React from "react";

export interface ItemProps {
  item: string;
  onItemSelected?: (item: string, e: any) => void;
}
export const Item: React.FunctionComponent<ItemProps> = ({ item, onItemSelected }: ItemProps) => {
  return (
    <div className={"list-group-item list-group-item-action"} onClick={(e) => onItemSelected!(item, e)}>
      <span className="d-flex">{item}</span>
    </div>
  );
};
