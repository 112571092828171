import { Action } from "components/Rosetta/reducers/actions";
import { Reducer } from "react";
import { AppState } from "../rosettaPage";

export interface RosettaMessage {
  api: string;
  datatype: string;
  formulakey: string;
  parameters: Parameters;
}

export interface Parameters {
  attributes: string;
  kpis: string;
  date: string;
  attributefilter: string;
  filter: string;
  kpifilter: string;
}

export interface RosettaMessageState {
  rosettaMessage: RosettaMessage;
}

export const rosettaMessageReducer: Reducer<AppState, Action> = (state: AppState, action: Action) => {
  if (action.type === "ROSETTA_MESSAGE_CHANGE") {
    return {
      ...state,
      rosettaMessage: action.payload,
      dateSelected: action.payload.parameters.date,
      apiSelected: action.payload.api,
      dataTypeSelected: action.payload.datatype,
    };
  }
  return state;
};
