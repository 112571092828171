import { apiRequester } from "../api";
import { Fetch, IColumn } from "../typings";
import { getJsonHandler } from "../utils";
import { IExploreDimensions, IExploreKpis } from "./typings";
import { mapDataExtract } from "./data.extract.map";

export async function GetDataExtractFields(dataType: string): Promise<IColumn[]> {
  const apiAttributesResult = await GetDataExtractAttributesApiCall(dataType, fetch.bind(window));

  const apiKpiResult = await GetDataExtractKpisApiCall(dataType, fetch.bind(window));

  if (apiAttributesResult && apiKpiResult) {
    return mapDataExtract(apiKpiResult, apiAttributesResult);
  }
  return null!;
}

const dataExtract_endpoint = process.env.REACT_APP_DATA_EXTRACT_ENDPOINT;

export async function GetDataExtractAttributesApiCall(dataType: string, fetch: Fetch): Promise<IExploreDimensions> {
  const apiResponse = await apiRequester({
    baseUri: `${dataExtract_endpoint}/dataextract/${dataType}/dimensions`,
    fetch,
    method: "GET",
  })({});
  return getJsonHandler<IExploreDimensions>()(apiResponse);
}

export async function GetDataExtractKpisApiCall(dataType: string, fetch: Fetch): Promise<IExploreKpis> {
  const apiResponse = await apiRequester({
    baseUri: `${dataExtract_endpoint}/dataextract/${dataType}/kpis`,
    fetch,
    method: "GET",
  })({});
  return getJsonHandler<IExploreKpis>()(apiResponse);
}
