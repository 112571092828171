import { filter } from "lodash";
import React from "react";
import * as ReactDOM from "react-dom";
import { InputPrefix } from "components/common/InputPrefix/InputPrefix";
import classNames from "classnames";
import { Icon } from "components/Rosetta/Icon";
import { replaceSpecialCharactersByWhiteSpace } from "common/utils/stringUtils";

export interface IComboBoxProps {
  items: string[];
  defaultItem: string;
  onChange: (tags: string) => void;
  larger?: boolean;
}

interface IComboBoxState {
  open: boolean;
  selectedItem: string;
  tags: string[];
  filterText: string;
}

export class ComboBox extends React.Component<IComboBoxProps, IComboBoxState> {
  constructor(props: IComboBoxProps) {
    super(props);
    this.state = {
      filterText: "",
      open: false,
      selectedItem: this.props.defaultItem,
      tags: this.props.items,
    };
    this.selectItem.bind(this);
  }

  public componentWillMount() {
    document.addEventListener("mousedown", this.outsideClick);
  }

  public componentWillUnmount() {
    document.removeEventListener("mousedown", this.outsideClick);
  }

  public componentDidUpdate(prevProps: IComboBoxProps) {
    if (prevProps.defaultItem !== this.props.defaultItem) {
      this.setState({
        selectedItem: this.props.defaultItem,
      });
    }
  }

  public render() {
    return (
      <div className="d-flex align-items-end">
        <div className="input-group" style={{ width: "100%" }}>
          <div className={"input-group-append input-group-merged"} style={{ right: "4px !important" }}>
            <button type="button" className="btn btn-link px-0" onClick={() => this.toggleCombo()}>
              <Icon
                iconName={this.state.open ? "arrow_drop_up" : "arrow_drop_down"}
                size={"md"}
                className={"pointer"}
              />
            </button>
          </div>
          <input
            className={`form-control  ${this.state.open ? "" : "ignore-read-only-style"}`}
            style={
              this.props.larger
                ? {
                    // width: "349px",
                    color: "transparent",
                    textShadow: "0 0 0 black",
                  }
                : {
                    width: "205px",
                    color: "transparent",
                    textShadow: "0 0 0 black",
                  }
            }
            // spellCheck={false}
            aria-label="Combobox"
            type="text"
            readOnly={true}
            value={this.state.selectedItem ?? ""}
            title={this.state.selectedItem}
            placeholder="Select any values"
            onClick={() => this.toggleCombo()}
          />
          {this.state.open && (
            <div
              className="card card-dropdown shadow-lg card-dropdown-overflow"
              style={{
                zIndex: 2100,
                marginBottom: "15%",
                // width: "350px"
              }}
            >
              <div className={"card-body p-0"}>
                <div
                  className="position-sticky bg-white w-100"
                  hidden={!this.state.open}
                  style={{ zIndex: 2100, top: "0px" }}
                >
                  <div className={classNames("input-group", "lg")}>
                    <InputPrefix iconName="search" />
                    <input
                      type="search"
                      className={"form-control"}
                      style={this.props.larger ? {} : { width: "auto" }}
                      placeholder="Search"
                      defaultValue={this.state.filterText}
                      onInput={this.setFilter.bind(this)}
                    />
                  </div>
                </div>

                {this.state.tags.map((item, index) => (
                  <div key={index} className={"list-group-item list-group-item-action action"}>
                    <a className="d-flex" onClick={() => this.selectItem(item)}>
                      {item}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  private toggleCombo() {
    this.setState({
      ...this.state,
      open: !this.state.open,
    });
  }

  private selectItem(item: string) {
    this.setState({
      ...this.state,
      selectedItem: item,
    });

    this.toggleCombo();
    // this.props.onChange(this.state.selectedItem);
    this.props.onChange(item);
  }

  private setFilter(event: any) {
    this.setState({ ...this.state, filterText: event.target.value });
    const newTags = this.getVisibleChoices(event.target.value);

    this.setTags(newTags);
  }

  private setTags(tagsUpdated: string[]) {
    this.setState({ ...this.state, tags: tagsUpdated });
  }

  public getVisibleChoices(filterText: string): string[] {
    if (!filterText || filterText.length === 0) {
      return this.props.items;
    }

    return filter(this.props.items, (tag) => {
      const splitNormalizedText = replaceSpecialCharactersByWhiteSpace(filterText).split(" ");
      return splitNormalizedText.every((element) => tag.toLocaleLowerCase().includes(element));
    });
  }

  private outsideClick = (event: any) => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        ...this.state,
        open: false,
      });
    }
    return true;
  };
}
