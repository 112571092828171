import { apiRequester } from "../api";
import { Fetch, IColumn, IExploreDimensions } from "../typings";
import { getJsonHandler } from "../utils";
import { IExploreIndicators, IAttributeValues } from "./typings";
import { mapApiBI } from "./cpro.bi.map";

export async function GetApiBiFields(dataType: string, bucketable = false): Promise<IColumn[]> {
  const kpiResult = await KpiApiCall(dataType, fetch.bind(window), bucketable);
  const attributeResult = await attributesApiCall(dataType, fetch.bind(window), bucketable);
  if (kpiResult && attributeResult) {
    return mapApiBI(kpiResult, attributeResult);
  }
  return null!;
}

const apiBi_endpoint = process.env.REACT_APP_API_BI_ENDPOINT;

export async function KpiApiCall(dataType: string, fetch: Fetch, bucketable = false): Promise<IExploreIndicators> {
  const apiResponse = await apiRequester({
    baseUri: `${apiBi_endpoint}/indicators${bucketable ? "/buckets" : ""}/${dataType}${bucketable ? "/kpis" : ""}`,
    fetch,
    method: "GET",
  })({});
  const dataResponse = await getJsonHandler<IExploreIndicators>()(apiResponse);
  return dataResponse;
}

export async function attributesApiCall(
  dataType: string,
  fetch: Fetch,
  bucketable = false
): Promise<IExploreDimensions> {
  const apiResponse = await apiRequester({
    baseUri: `${apiBi_endpoint}/indicators${bucketable ? "/buckets" : ""}/${dataType}/${
      bucketable ? "attributes" : "dimensions"
    }`,
    fetch,
    method: "GET",
  })({});

  const dataResponse = await getJsonHandler<IExploreDimensions>()(apiResponse);
  return dataResponse;
}

export async function AttributeValuesApiCall(dataType: string, attribute: string): Promise<IAttributeValues> {
  const apiResponse = await apiRequester({
    baseUri: `${apiBi_endpoint}/attributes/${dataType}/${attribute}/values`,
    fetch: fetch.bind(window),
    method: "GET",
  })({});
  return getJsonHandler<IAttributeValues>()(apiResponse);
}
