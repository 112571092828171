import React from "react";

interface Props {
  isShown: boolean;
  message: string;
}
export const ItemMessage: React.FunctionComponent<Props> = ({ message, isShown = true }: Props) => {
  return isShown ? (
    <div className="py-2 pl-3 list-group-item">
      <div className="text-secondary">{message}</div>
    </div>
  ) : null;
};
