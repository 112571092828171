import DropDownList from "../../common/component/DropDownList";
import { Params } from "components/Rosetta/params";
import React from "react";
import { FormField } from "components/common/ReadOnlyField/FormField";

export interface ApiFiltersProps {
  apiSelected: string;
  dataTypeSelected: string;
  onApiNameChange: (apiName: string) => void;
  onDataTypeChange: (dataType: string) => void;
}

const ApiFilters: React.FC<ApiFiltersProps> = ({
  apiSelected,
  dataTypeSelected,
  onApiNameChange,
  onDataTypeChange,
}: ApiFiltersProps) => {
  const handleDataTypeChange = (dataTypeSelected: string): void => {
    onDataTypeChange(dataTypeSelected);
  };

  const handleApiNameChange = (apiSelected: string): void => {
    onApiNameChange(apiSelected);
  };

  return (
    <div className="row  mb-2">
      <FormField id="apiName" label="Api name" className="col-6 col-md-3">
        <DropDownList onChange={handleApiNameChange} tags={Params.GetApis()} selected={apiSelected} />
      </FormField>
      <FormField id="dataType" label="Data type" className="col-6 col-md-3">
        <DropDownList
          onChange={handleDataTypeChange}
          tags={Params.GetDataTypes(apiSelected)}
          selected={dataTypeSelected}
        />
      </FormField>
    </div>
  );
};
export default ApiFilters;
