import React from "react";
import { noop } from "lodash";
import { ClickableButtonProps } from "./Buttons";

const CancelButton: React.FC<ClickableButtonProps> = ({ label, onClick, className }: ClickableButtonProps) => (
  <button type="button" className={className ? className : "btn btn-lg btn-outline-primary"} onClick={onClick || noop}>
    {label || "Cancel"}
  </button>
);

const SubmitButton: React.FunctionComponent<ClickableButtonProps> = ({
  onClick,
  label,
  className,
  iconClassName,
  isInModal,
  disabled,
  icon,
}: ClickableButtonProps) => (
  <button
    type="button"
    disabled={disabled}
    data-dismiss={isInModal ? "modal" : ""}
    className={className ? className : "btn btn-lg btn-primary mr-2"}
    onClick={onClick || noop}
  >
    <>
      {label || "Submit"}
      <i className={iconClassName ? iconClassName : "icon icon-sm"}> {icon}</i>
    </>
  </button>
);

interface ActionButtonsProps {
  showResult: () => void;
  reset: () => void;
  apply: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ showResult, reset, apply }: ActionButtonsProps) => {
  return (
    <>
      <CancelButton onClick={showResult} label="Show result" className="btn btn-black btn-lg mr-2" />
      <SubmitButton onClick={reset} label="Reset" className="btn btn-lg btn-secondary mr-2" />
      <SubmitButton onClick={apply} label="Apply" className="btn btn-lg btn-danger" />
    </>
  );
};

export default ActionButtons;
